import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { BehaviorSubject, Subscription } from "rxjs";
import { Incident } from "src/app/_share/_models/incident";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { Leave } from "src/app/_share/_models/leave";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { LeaveBalance } from "src/app/_share/_models/leaveBalance";
import { LeaveBalanceService } from "src/app/_share/_services/leaveBalance.service";
import { Employee } from "src/app/_share/_models/employee";

@Component({
  selector: "app-view-leave-balance",
  templateUrl: "./view-leave-balance.component.html",
  styleUrls: ["./view-leave-balance.component.scss"],
})
export class ViewLeaveBalanceComponent implements OnInit {
  // employeeIncidentHistoryQuery$: Subscription | undefined;

  // employeeIncidentHistory: any[] = [];
  // employeeIncidenthistory$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
  //   []
  // );

  employeeLeaveHistoryQuery$: Subscription | undefined;
  employeeLeaveHistory: any[] = [];
  employeeLeavehistory$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  employees: any[] = [];
  loadingHistory: boolean = false;
  leave: any;
  leaveBalance: any;
  employee: any;

  constructor(
    private config: DynamicDialogConfig,
    private leaveService: LeaveService, // private incidentService: IncidentService
    private leaveBalanceService: LeaveBalanceService,
    public employeeService: EmployeeService
  ) {}

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }

  getEmployeeNameById(id: number): string {
    // Find employee by ID and return their full name
    const employee = this.employees.find((emp) => emp.id === id);
    return employee
      ? // ${employee.employee_code}
        ` ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  ngOnInit(): void {
    this.loadEmployees();
    this.leaveBalance = this.config.data;
    this.getEmployeeLeaveBalanceHistory();
    // this.employee = this.config.data;
    // this.getEmployeeIncidentHistory();
  }

  getEmployeeDetails() {
    // Assuming you have an API or service that fetches employee by ID
    this.employeeService.getEmployees().subscribe((employees: Employee[]) => {
      this.employee = employees.find(
        (emp) => emp.id === this.leaveBalance.employee_id
      ); // Set the employee data once fetched
    });
  }

  xgetEmployeeLeaveBalanceHistory() {
    this.loadingHistory = true;
    this.employeeLeaveHistoryQuery$ = this.leaveBalanceService
      .getLeaveBalanceByEmployeeId(this.leaveBalance.id)
      .subscribe((leaveBalances: LeaveBalance[]) => {
        this.employeeLeaveHistory = leaveBalances;
        this.employeeLeavehistory$.next(leaveBalances);
        this.loadingHistory = false;
      });
  }
  getEmployeeLeaveBalanceHistory() {
    this.loadingHistory = true;
    this.employeeLeaveHistoryQuery$ = this.leaveService
      .getLeaveByEmployeeId(this.leaveBalance.employee_id)
      .subscribe((leaves: Leave[]) => {
        this.employeeLeaveHistory = leaves;
        this.employeeLeavehistory$.next(leaves);
        this.loadingHistory = false;
      });
  }

  ngOnDestroy(): void {
    // if (this.employeeIncidentHistoryQuery$) {
    //   this.employeeIncidentHistoryQuery$.unsubscribe();
    // }
    if (this.employeeLeaveHistoryQuery$) {
      this.employeeLeaveHistoryQuery$.unsubscribe();
    }
  }

  getLeaveStatusSeverity(value: string): string {
    switch (value) {
      case "Annual":
        return "success";
      case "Family Responsibility":
        return "info";
      case "Maternity":
      case "Study":
        return "warning";
      case "Unpaid":
        return "danger";
      default:
        return "info";
    }
  }
}
