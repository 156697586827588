import { Injectable, Input } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { Employee } from "../_models/employee";
import { ApiService } from "src/app/_core/_services/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  // Properties
  // ----------

  //not have in service and only return in component
  private employees: Employee[] = [];
  private employees$ = new BehaviorSubject<Employee[]>([]);

  constructor(public api: ApiService, private http: HttpClient) {}

  xgetEmployees(): Observable<Employee[]> {
    return this.api
      .getAPI<Employee[]>("employees/get")
      .pipe(map((res) => res.body.data));
  }
  //this is fastcrud
  // getEmployees(): Observable<Employee[]> {
  //   return this.api.getAPI<Employee[]>("employees/get").pipe(
  //     map((res) =>
  //       res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
  //     ),
  //     tap((employees) => this.employees$.next(employees))
  //   );
  // }
  // used for sorting by id
  // getEmployees(): Observable<Employee[]> {
  //   return this.api.getAPI<Employee[]>("employees").pipe(
  //     map((res) =>
  //       res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
  //     ),
  //     tap((employees) => this.employees$.next(employees))
  //   );
  // }
  // used for sorting by last name
  getEmployees(): Observable<Employee[]> {
    return this.api.getAPI<Employee[]>("employees").pipe(
      map((res) => {
        // Filter employees to exclude those with a 'terminated' status (case-insensitive)
        return res.body
          .filter(
            (employee: Employee) =>
              employee.status && employee.status.toLowerCase() !== "terminated"
          )
          .sort((a: Employee, b: Employee) => {
            return (a.last_name ?? "").localeCompare(b.last_name ?? "");
          });
      }),
      tap((employees) => this.employees$.next(employees))
    );
  }

  WaddEmployee(newEmployee: Employee): Observable<Employee> {
    return this.api.postAPI<Employee>("employees/create", newEmployee).pipe(
      map((res) => res.body),
      tap(() => this.refreshEmployees())
    );
  }
  // more like update
  addEmployee(newEmployee: Employee): Observable<any> {
    return this.api.postAPI<Employee>("employees/create", newEmployee).pipe(
      tap((res: any) => {
        console.log("Employee added", res);
      }),
      switchMap(() => this.getEmployees())
    );
  }
  // update and del

  updateEmployee(employeeId: number, employeeData: Employee): Observable<any> {
    return this.api
      .patchAPI<Employee>("employees/update", employeeId, employeeData)
      .pipe(
        tap((res: any) => {
          console.log("Employee updated", res);
        }),
        switchMap(() => this.getEmployees())
      );
  }

  deleteEmployee(employeeId: number): Observable<any> {
    return this.api
      .deleteAPI<any>("employees/delete", employeeId)
      .pipe(tap(() => this.refreshEmployees()));
  }
  refreshEmployees(): Observable<Employee[]> {
    return this.getEmployees().pipe(
      tap((employees) => {
        //this.employees = employees;
        this.employees$.next(employees);
      })
    );
  }
  // sendSms(hearingId: number): Observable<any> {
  //   return this.api.postAPI<any>(`sms?hearing_id=${hearingId}`, {}).pipe(
  //     catchError((error) => {
  //       console.error("Error sending SMS:", error);
  //       return of({ error: true, message: "Failed to send SMS." });
  //     })
  //   );
  // }
  // sendSms(hearingId: number): Observable<any> {
  //   return this.api.postAPI<any>(`sms?hearing_id=${hearingId}`, {}).pipe(
  //     catchError((error) => {
  //       console.error("Error sending SMS:", error);
  //       // Check if the error response contains a message and status
  //       const errorMessage = error?.error?.detail || "Failed to send SMS.";
  //       return of({ error: true, message: errorMessage });
  //     })
  //   );
  // }
  sendSms(
    hearingId: number,
    hearingDate: Date,
    venue: string
  ): Observable<any> {
    const payload = {
      hearing_id: hearingId,
      hearing_date: hearingDate,
      venue: venue,
    };
    return this.api.postAPI<any>("sms", payload);
  }

  get employeesObservable$(): Observable<Employee[]> {
    return this.employees$.asObservable();
  }
  private handleError<T>(
    operation = "operation",
    error: any,
    result?: T
  ): Observable<T> {
    console.error(`${operation} failed: ${error.message}`);
    return of(result as T);
  }
}
