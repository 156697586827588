<div *ngIf="this.leaves$ | async; else loadedContent">
  <div class="flex p-3 flex-column">
    <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
    </div>
  </div>
</div>

<!-- <div *ngIf="leave"> -->
<!-- Loaded content -->
<ng-template #loadedContent>
  <div class="border-round">
    <div class="grid grid-nogutter">
      <div class="col-12 pt-3 px-3">
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Leave
        </div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Employee Details</div>
        <div class="text-900">
          {{ getEmployeeNameById(leave.employee_id) }}
        </div>
      </div>
      <div class="col-12 md:col-3 p-3 block">
        <div class="text-500 font-medium mb-2">Leave Type</div>
        <div class="text-900">{{ leave.leave_action_type }}</div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Leave Start Period</div>
        <div class="text-900">{{ leave.start_period }}</div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Leave end Period</div>
        <div class="text-900">{{ leave.end_period }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Annual Leave Amount</div>
        <div class="text-900">{{ leave.annual_leave_amount }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Sick Leave Amount</div>
        <div class="text-900">{{ leave.sick_leave_amount }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Maternity Leave Amount</div>
        <div class="text-900">{{ leave.maternity_leave_amount }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Unpaid Leave Amount</div>
        <div class="text-900">{{ leave.unpaid_leave_amount }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">
          Family Responsibility Leave Amount
        </div>
        <div class="text-900">
          {{ leave.family_responsiblity_leave_amount }}
        </div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Study Leave Amount</div>
        <div class="text-900">{{ leave.study_leave_amount }}</div>
      </div>

      <!-- Evidence files -->
      <div class="col-4 p-3">
        <div class="text-700 font-medium text-lg mb-3">Evidence Files</div>
        <ng-container
          *ngIf="evidencesFiles.length === 0; else evidenceFilesList"
        >
          <div
            class="text-500 font-medium border-top-1 surface-border p-3 text-center"
          >
            No files attached
          </div>
        </ng-container>
        <ng-template #evidenceFilesList>
          <div
            class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
            *ngFor="let file of evidencesFiles"
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <div>
                <div class="text-900 mr-2">{{ file.original_filename }}</div>
                <div class="text-500 text-xs">
                  uploaded {{ file.date_uploaded | dateAsAgo }}
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              pButton
              pRipple
              icon="pi pi-download"
              class="p-button-outlined mt-2 md:mt-0"
              style="text-decoration: none"
              (click)="
                downloadFile(
                  file.unique_file_reference,
                  file.original_filename
                );
                $event.stopPropagation()
              "
            ></a>
          </div>
        </ng-template>
      </div>

      <!-- Admin files -->
      <div class="col-4 p-3">
        <div class="text-700 font-medium text-lg mb-3">Admin Files</div>
        <ng-container *ngIf="adminFiles.length === 0; else adminFilesList">
          <div
            class="text-500 font-medium border-top-1 surface-border p-3 text-center"
          >
            No files attached
          </div>
        </ng-container>
        <ng-template #adminFilesList>
          <div
            class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
            *ngFor="let file of adminFiles"
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <div>
                <div class="text-900 mr-2">{{ file.original_filename }}</div>
                <div class="text-500 text-xs">
                  uploaded {{ file.date_uploaded | dateAsAgo }}
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              pButton
              pRipple
              icon="pi pi-download"
              class="p-button-outlined mt-2 md:mt-0"
              style="text-decoration: none"
              (click)="
                downloadFile(
                  file.unique_file_reference,
                  file.original_filename
                );
                $event.stopPropagation()
              "
            ></a>
          </div>
        </ng-template>
      </div>

      <!-- Generated files -->
      <div class="col-4 p-3">
        <div class="text-700 font-medium text-lg mb-3">Generated Files</div>
        <ng-container
          *ngIf="generatedFiles.length === 0; else generatedFilesList"
        >
          <div
            class="text-500 font-medium border-top-1 surface-border p-3 text-center"
          >
            No files attached
          </div>
        </ng-container>
        <ng-template #generatedFilesList>
          <div
            class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
            *ngFor="let file of generatedFiles"
          >
            <div class="flex align-items-center">
              <span class="block pi pi-file mr-2"></span>
              <div>
                <div class="text-900 mr-2">{{ file.original_filename }}</div>
                <div class="text-500 text-xs">
                  uploaded {{ file.date_uploaded | dateAsAgo }}
                </div>
              </div>
            </div>
            <a
              href="javascript:void(0);"
              pButton
              pRipple
              icon="pi pi-download"
              class="p-button-outlined mt-2 md:mt-0"
              style="text-decoration: none"
              (click)="
                downloadGeneratedFile(file.id, leave.id);
                $event.stopPropagation()
              "
            ></a>
          </div>
        </ng-template>
      </div>

      <!-- Actions Block -->
      <div class="col-12 pt-3 px-3">
        <!-- lg:col-6 mt-4 -->
        <!-- <div class="surface-card p-4 shadow-2 border-round"> -->
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Action(s)
        </div>
      </div>

      <!-- actions -->

      <div class="col-12 p-3">
        <div class="formgrid p-fluid">
          <div class="field mb-4 col-12 lg:col-12"></div>
        </div>

        <div class="formgrid p-fluid" [formGroup]="leaveForm">
          <!----ngif for hiding-->

          <!-- File Upload Field -->
          <div class="field mb-4 col-12 lg:col-12">
            <label for="file_id">File</label>
            <p-fileUpload
              name="file[]"
              #fubadvanced
              [multiple]="true"
              [customUpload]="true"
              accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              [maxFileSize]="1000000"
              (uploadHandler)="onFileUpload($event, fubadvanced)"
            >
              <ng-template pTemplate="content">
                <div *ngIf="uploadedFiles.length">
                  <div
                    class="flex p-1 flex-column"
                    *ngFor="let file of uploadedFiles"
                  >
                    <div class="flex align-items-center">
                      <span class="block pi pi-file mr-2"></span>
                      <span class="text-900"
                        >{{ file.original_filename }} ({{
                          file.file_size
                        }}
                        bytes)</span
                      >
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-fileUpload>
          </div>

          <!----ngif for hiding-->
          <div *ngIf="leave?.leave_status == 'pending'">
            <div class="field">
              <label for="leaveResolution" class="text-500 font-medium mb-2"
                >Leave Approval</label
              >
              <p-dropdown
                formControlName="resolution"
                [options]="resolutionOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Investigation Verdict"
                class="w-full"
              ></p-dropdown>
              <div
                *ngIf="
                  leaveForm.get('resolution')?.invalid &&
                  (leaveForm.get('resolution')?.touched ||
                    leaveForm.get('resolution')?.dirty)
                "
                class="p-error mt-1"
              >
                <small><strong>Leave Approval</strong> is required</small>
              </div>
            </div>
            <div class="field mt-3">
              <button
                pButton
                label="Conclude Leave Approval"
                class="p-button-success w-full"
                (click)="concludeLeaveAction()"
              ></button>
            </div>
          </div>

          <div class="field mt-2">
            <button
              pButton
              label="Generate Warning Document"
              class="p-button-outlined w-full"
              icon="pi pi-download"
              (click)="downloadWarningDocument(leave.id)"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</ng-template>
