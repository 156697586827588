<div
  *ngIf="this.createHearings$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
  </div>
</div>
<ng-template #loadedContent>
  <form [formGroup]="hearingForm" (ngSubmit)="onAddHearingFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <!-- action_taken Field -->
        <div class="field mb-4 col-6">
          <label for="venue">Venue</label>

          <input
            class="form-control"
            id="venue"
            formControlName="venue"
            placeholder="Enter venue"
            type="text"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              hearingForm.get('venue')?.invalid &&
              (hearingForm.get('venue')?.touched ||
                hearingForm.get('venue')?.dirty)
            "
            class="p-error"
          >
            Meeting Place is required.
          </div>
        </div>
        <!-- incident_id Dropdown -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incident_id">Incidents</label>
          <p-dropdown
            [options]="incidents"
            optionLabel="label"
            optionValue="value"
            formControlName="incident_id"
            placeholder="Select an Incident pending a hearing"
            [filter]="true"
            filterPlaceholder="Search an Incident pending a hearing"
            [(ngModel)]="selectedIncident"
            [readonly]="true"
          >
            <ng-template let-item pTemplate="item">
              <div class="flex justify-between custom-dropdown-item">
                <span>{{ item.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <div
            *ngIf="
              hearingForm.controls['incident_id'].invalid &&
              (hearingForm.controls['incident_id'].dirty ||
                hearingForm.controls['incident_id'].touched)
            "
            class="p-error"
          >
            Incident is required.
          </div>
        </div>
        <!-- hearing_date Field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="hearing_date">Hearing Date</label>
          <p-calendar
            id="hearing_date"
            name="hearing_date"
            formControlName="hearing_date"
            placeholder="Please select a date"
            dateFormat="yy-mm-dd"
            [showTime]="true"
            [inline]="false"
            appendTo="body"
            type="text"
            dataType="string"
          ></p-calendar>
          <div
            *ngIf="
              hearingForm.get('hearing_date')?.invalid &&
              (hearingForm.get('hearing_date')?.touched ||
                hearingForm.get('hearing_date')?.dirty)
            "
            class="p-error"
          >
            Meeting Date is required.
          </div>
        </div>
        <!-- existing  files Field -->
        <div class="field mb-4 col-12 lg:col-6" *ngIf="!isInEditMode">
          <label for="existingFiles">Existing Files</label>
        </div>

        <div
          class="flex flex-column lg:flex-row field mb-4 col-12 lg:col-12"
          *ngIf="isInEditMode"
        >
          <div class="col-12 lg:col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Evidence Files</div>
            <ng-container
              *ngIf="evidencesFiles.length === 0; else evidenceFilesList"
            >
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>

            <ng-template #evidenceFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of evidencesFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadFile(
                      file.unique_file_reference,
                      file.original_filename
                    );
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>

          <!-- Admin files -->
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Admin Files</div>
            <ng-container *ngIf="adminFiles.length === 0; else adminFilesList">
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #adminFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of adminFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadFile(
                      file.unique_file_reference,
                      file.original_filename
                    );
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>

          <!-- Generated files -->
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Generated Files</div>
            <ng-container
              *ngIf="generatedFiles.length === 0; else generatedFilesList"
            >
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #generatedFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of generatedFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadFile(file.id, hearing.id); $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- File Upload Field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="file_id">File</label>
          <p-fileUpload
            name="file[]"
            #fubadvanced
            [multiple]="true"
            [customUpload]="true"
            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            [maxFileSize]="1000000"
            (uploadHandler)="onFileUpload($event, fubadvanced)"
          >
            <ng-template pTemplate="content">
              <div *ngIf="uploadedFiles.length">
                <div
                  class="flex p-1 flex-column"
                  *ngFor="let file of uploadedFiles"
                >
                  <div class="flex align-items-center">
                    <span class="block pi pi-file mr-2"></span>
                    <span class="text-900"
                      >{{ file.original_filename }} ({{
                        file.file_size
                      }}
                      bytes)</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </p-fileUpload>
        </div>
        <div class="field col-12">
          <p-button
            styleClass="bg-blue-400"
            type="submit"
            label="Submit"
            class="w-6"
          ></p-button>
          <!-- [loading]="loading" -->
        </div>
      </div>
    </div>
  </form>
</ng-template>
