import { Component, Inject, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from "primeng/dynamicdialog";
import {
  BehaviorSubject,
  catchError,
  finalize,
  switchMap,
  tap,
  throwError,
} from "rxjs";

import { Observable } from "rxjs";
import { FilesService } from "src/app/_share/_services/files.service";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { ActionService } from "src/app/_share/_services/action.service";
import { Action } from "src/app/_share/_models/action";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Incident } from "src/app/_share/_models/incident";
import { FileUpload } from "primeng/fileupload";

@Component({
  selector: "app-view-incident",
  templateUrl: "./view-incident.component.html",
  styleUrls: ["./view-incident.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class ViewIncidentComponent implements OnInit {
  Incidents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  incident: Incident = this.config.data.incident;
  // employeeName: string = "Unknown";
  // transgression: string = "Unknown";
  employees: any[] = [];
  transgressions: any[] = [];
  employeeIncidentHistory: any[] = [];
  incidentActions: Action[] = [];
  resolutionOptions: any[];
  evidencesFiles: any[] = [];
  generatedFiles: any[] = [];
  adminFiles: any[] = [];

  filterFilesByType() {
    if (this.incident && this.incident.files) {
      this.evidencesFiles = this.incident.files.filter(
        (file) => file.file_association_type === "evidence"
      );
      this.generatedFiles = this.incident.files.filter(
        (file) => file.file_association_type === "generated"
      );
      this.adminFiles = this.incident.files.filter(
        (file) => file.file_association_type === "admin"
      );
    }
  }

  incidentForm: FormGroup = this.formBuilder.group({
    resolution: ["", Validators.required],
  });

  constructor(
    public ref: DynamicDialogRef, // Inject DynamicDialogRef
    private config: DynamicDialogConfig,
    public employeeService: EmployeeService,
    public filesService: FilesService,
    public transgressionService: TransgressionService,
    private incidentService: IncidentService,
    private actionService: ActionService,
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    private http: HttpClient
  ) {
    this.resolutionOptions = [
      { label: "Guilty", value: true },
      { label: "Not Guilty", value: false },
    ];
  }

  ngOnInit() {
    this.incident = this.config.data.incident;
    console.log("evidencesFiles", this.incident);

    this.filterFilesByType();
    // this.loadEmployees();
    // this.loadTransgressions();
    this.loadEmployeeIncidentHistory(); // Load history of incidents related to the employee
    this.loadIncidentActions(); // Load actions related to the incident
    setTimeout(() => {
      this.Incidents$.next(false); // Set loading to false after data is loaded
    }, 2000); // Adjust the timeout as necessary
  }

  uploadedFiles: any[] = [];

  onFileUpload(event$: any, uploader: FileUpload) {
    for (let file of event$.files) {
      let formData = new FormData();
      formData.append("file", file);

      this.filesService.uploadFile(formData, this.incident.id).subscribe({
        next: (response: any) => {
          console.log("File uploaded successfully", response);
          // Store uploaded file data for later use
          const uploadedFileData = response.body.data; // Capture the response data
          this.uploadedFiles.push(uploadedFileData);
        },
        error: (err: any) => {
          console.error("Error uploading file", err);
        },
      });
    }
    // Clear the file list in the uploader
    uploader.clear();
  }
  // Method to map offence_penalty values to their respective text
  getPenaltyText(penalty: number): string {
    switch (penalty) {
      case 1:
        return "Verbal Warning";
      case 2:
        return "Written Warning";
      case 3:
        return "Final Warning";
      case 4:
        return "Termination";
      default:
        return "Unknown Penalty";
    }
  }

  getTransgressionPenaltyById(id: number | undefined): string {
    if (!id) {
      return "loading...";
    }
    const transgression = this.transgressions.find(
      (trans: any) => trans.id === id
    );
    return transgression
      ? this.getPenaltyText(transgression.offence_penalty)
      : "loading...";
  }

  getFileLinks(files: any[]): any[] {
    if (!files || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      unique_file_reference: file.unique_file_reference,
      original_filename: file.original_filename,
    }));
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.filesService
      .downloadFile(unique_file_reference, original_filename)
      .subscribe(
        () => {
          console.log("File downloaded successfully");
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }
  loadEmployeeIncidentHistory() {
    if (this.incident && this.incident.employee_id) {
      this.incidentService
        .getIncidentsByEmployeeId(this.incident.employee_id)
        .subscribe((employeeIncidentHistory) => {
          this.employeeIncidentHistory = employeeIncidentHistory;
        });
      // this.incidentService.getIncidents().subscribe((incidents) => {
      //   this.employeeIncidentHistory = incidents.filter((inc: any) => {
      //     return (
      //       inc.employee_id === this.incident.employee_id &&
      //       inc.resolution === true // Only include incidents with a "Guilty" resolution
      //     );
      //   });
      // });
    }
  }
  loadIncidentActions() {
    if (this.incident && this.incident.employee_id) {
      this.actionService
        .getActionsByIncidentId(this.incident.id)
        .subscribe((incidentActions) => {
          this.incidentActions = incidentActions;
        });
    }
  }

  downloadWarningDocument(incidentId: number) {
    this.filesService.downloadGeneratedHearingFile(incidentId).subscribe(
      (response: any) => {
        console.log("response received:", response);

        let defaultFileName = `WrittenWarning_${incidentId}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  downloadGeneratedFile(file_id: number, incident_id: number) {
    this.filesService.downloadGeneratedFile(file_id, incident_id).subscribe(
      (response: any) => {
        console.log("Response received:", response);

        let defaultFileName = `HearingDocument_${incident_id}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1]
              .replace(/['"]/g, "")
              .replace("utf-8", "")
              .replace(/%20/g, "_")
              .trim(); // Replace %20 with underscores
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }

        // Create a blob and trigger the download
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Use the sanitized filename
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }
  // Method to submit action and update incident status
  submitAction() {
    const actionData: Action = {
      details_comments: "Incident acknowledged by manager",
      action_type: "incident_acknowledged",
      incident_id: this.incident.id,
      manager_id: 1,
    };
    const updatedIncident = {
      ...this.incident,
    };

    this.actionService.postAction(actionData).subscribe({
      next: (response) => {
        console.log("Action created successfully", response);

        this.messageService.add({
          severity: "success",
          summary: "Action Submitted",
          detail:
            "The action was successfully created and incident status updated.",
        });

        // Refresh incident actions and status
        this.loadIncidentActions();
        this.incidentService
          .updateIncident(this.incident.id, updatedIncident)
          .pipe(
            tap(() => {
              console.log("Incident updated successfully");
            })
          );
        // Close the dialog or navigate as necessary
        this.ref.close(updatedIncident);
      },
      error: (error) => {
        console.error("Error submitting action", error);
        this.messageService.add({
          severity: "error",
          summary: "Submission Failed",
          detail: "There was an issue submitting the action. Please try again.",
        });
      },
    });
  }

  concludeIncident() {
    if (this.incidentForm.invalid) {
      this.incidentForm.markAllAsTouched();
      return;
    }

    const resolution = this.incidentForm.get("resolution")?.value;
    const updatedIncident = {
      ...this.incident,
      // incident_status: "concluded",
      // action_taken: "closed incident",
      resolution: resolution, // Include the resolution value
    };

    // Update the incident first
    this.incidentService
      .updateIncident(this.incident.id, updatedIncident)
      .pipe(
        switchMap(() => {
          // After updating, determine the action
          return this.incidentService.determineAction(this.incident.id);
        })
      )
      .subscribe(
        (response) => {
          // Handle success response
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Incident concluded and action determined successfully.",
          });

          // Check if the resolution is true and action taken is "Verbal Warning Issued"
          const actionTaken = response?.action_taken;
          if (resolution === true && actionTaken != "Verbal Warning Issued") {
            this.downloadWarningDocument(this.incident.id);
          }
          // Trigger download if resolution is true
          // if (resolution === true) {
          //   this.downloadWarningDocument(this.incident.id);
          // }

          this.ref.close(updatedIncident);
        },
        (error) => {
          // Handle error response
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "There was an error processing the incident.",
          });
        }
      );
  }
}
