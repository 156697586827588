import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { StatisticsService } from "src/app/_share/_services/statistics.service";

@Component({
  selector: "app-statistics-bar",
  templateUrl: "./statistics-bar.component.html",
  styleUrls: ["./statistics-bar.component.scss"],
})
export class StatisticsBarComponent implements OnInit {
  incidents: any;
  hearings: any;
  employees: any;
  transgressions: any;

  incidents$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  hearings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  employees$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  transgressions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private ss: StatisticsService) {}

  ngOnInit(): void {
    this.ss.getEmployeeStatistics().subscribe((res) => {
      console.log(res);
      this.employees = res;
      this.employees$.next(res);
    });
    this.ss.getIncidentStatistics().subscribe((res) => {
      console.log(res);
      this.incidents = res;
      this.incidents$.next(res);
    });
    this.ss.getTransgressionStatistics().subscribe((res) => {
      this.transgressions = res;
      this.transgressions$.next(res);
    });
    this.ss.getHearingStatistics().subscribe((res) => {
      this.hearings = res;
      this.hearings$.next(res);
    });
  }
}
