<div *ngIf="employee">
  <!-- Loaded content -->
  <div class="border-round">
    <div class="grid grid-nogutter">
      <div class="col-12 pt-3 px-3">
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Employee
        </div>
      </div>
      <div class="col-12 md:col-2 p-3">
        <div class="text-500 font-medium mb-2">Employee Code</div>
        <div class="text-900 text-3xl">
          {{ employee.employee_code }}
        </div>
      </div>
      <div class="col-12 md:col-5 p-3 block">
        <div class="text-500 font-medium mb-2">Employee First Name</div>
        <div class="text-900">{{ employee.first_name }}</div>
      </div>
      <div class="col-12 md:col-5 p-3">
        <div class="text-500 font-medium mb-2">Employee Last Name</div>
        <div class="text-900">{{ employee.last_name }}</div>
      </div>
      <div class="col-12 md:col-5 p-3">
        <div class="text-500 font-medium mb-2">Employee Email</div>
        <div class="text-900">{{ employee.email }}</div>
      </div>
      <div class="col-12 md:col-5 p-3">
        <div class="text-500 font-medium mb-2">Employee Phone</div>
        <div class="text-900">{{ employee.phone }}</div>
      </div>
      <div class="col-12 md:col-5 p-3">
        <div class="text-500 font-medium mb-2">Employee Department</div>
        <div class="text-900">{{ employee.department }}</div>
      </div>
      <div class="col-12 md:col-5 p-3">
        <div class="text-500 font-medium mb-2">Employee Position</div>
        <div class="text-900">{{ employee.position }}</div>
      </div>

      <!-- {{ employee | json }} -->

      <div class="col-12 p-3">
        <div *ngIf="loadingHistory; else historyLoaded">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <ng-template #historyLoaded>
          <div class="text-500 font-medium mb-2">Employee Incident History</div>
          <div class="text-900 line-height-3">
            <ng-container
              *ngIf="
                (employeeIncidenthistory$ | async)?.length;
                else noDataTemplate
              "
            >
              <p-timeline
                [value]="(employeeIncidenthistory$ | async) || []"
                align="alternate"
                class="p-1"
              >
                <ng-template pTemplate="content" let-historyItem>
                  <small class="p-text-secondary">{{
                    historyItem.date | date : "YYYY-MM-dd"
                  }}</small>
                </ng-template>
                <ng-template pTemplate="opposite" let-historyItem>
                  <div
                    [style]="{ width: '100%' }"
                    class="surface-card border-1 border-300 p-3"
                  >
                    <div class="text-900 font-semibold mb-2">
                      {{ historyItem.category | uppercase }}
                    </div>
                    <div class="text-900 line-height-1">
                      <p-tag
                        [severity]="
                          getIncidentStatusSeverity(historyItem.incident_status)
                        "
                        >{{ historyItem.incident_status | uppercase }}</p-tag
                      >
                    </div>
                    <div class="text-500 font-medium my-1">
                      Incident Details:
                    </div>
                    <div class="text-900 line-height-3">
                      {{ historyItem.description }}
                    </div>
                  </div>
                </ng-template>
              </p-timeline>
            </ng-container>
            <ng-template #noDataTemplate>
              <div
                class="flex p-3 flex-column align-items-center justify-content-center"
              >
                <p>No history available at present</p>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
