import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "src/app/_core/_services/api.service";
import { Suspension } from "../_models/suspensions";
import { map, switchMap, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class SuspensionService {
  private suspensions$ = new BehaviorSubject<Suspension[]>([]);

  constructor(public api: ApiService) {}

  // Get list of suspensions
  getSuspensions(): Observable<Suspension[]> {
    return this.api.getAPI<Suspension[]>("suspensions").pipe(
      map((res) =>
        res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
      ),
      tap((suspensions) => this.suspensions$.next(suspensions))
    );
  }

  // Add a new suspension
  addSuspension(newSuspension: Suspension): Observable<any> {
    return this.api.postAPI<Suspension>("suspensions", newSuspension).pipe(
      tap((res: any) => {
        console.log("new suspension added", res);
      }),
      switchMap(() => this.refreshSuspensions())
    );
  }

  // Update an existing suspension
  updateSuspension(
    suspensionId: number,
    suspensionData: Suspension
  ): Observable<any> {
    return this.api
      .patchAPI<Suspension>("suspensions", suspensionId, suspensionData)
      .pipe(
        tap((res: any) => {
          console.log("suspension updated", res);
        }),
        switchMap(() => this.refreshSuspensions())
      );
  }

  // Delete a suspension
  deleteSuspension(suspensionId: number): Observable<any> {
    return this.api.deleteAPI<Suspension>("suspensions", suspensionId).pipe(
      tap((res: any) => {
        console.log("suspension deleted", res);
      }),
      switchMap(() => this.refreshSuspensions())
    );
  }

  // Refresh suspensions list
  refreshSuspensions(): Observable<any> {
    return this.getSuspensions().pipe(
      tap((suspensions) => {
        this.suspensions$.next(suspensions);
      })
    );
  }
}
