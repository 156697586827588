import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ApiService } from "src/app/_core/_services/api.service";

@Injectable({
  providedIn: "root",
})
export class StatisticsService {
  constructor(private api: ApiService) {}

  getEmployeeStatistics() {
    return this.api.getAPI("statistics/employees").pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getIncidentStatistics() {
    return this.api.getAPI("statistics/incidents").pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getHearingStatistics() {
    return this.api.getAPI("statistics/hearings").pipe(
      map((res) => {
        return res.body;
      })
    );
  }

  getTransgressionStatistics() {
    return this.api.getAPI("statistics/transgressions").pipe(
      map((res) => {
        return res.body;
      })
    );
  }
}
