import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { FilesService } from "src/app/_share/_services/files.service";
import { CreateHearingComponent } from "../create-hearing/create-hearing.component";
import { HearingService } from "src/app/_share/_services/hearing.service";
import { Hearing } from "src/app/_share/_models/hearing";
import { ViewHearingComponent } from "../view-hearing/view-hearing.component";

@Component({
  selector: "app-hearing",
  templateUrl: "./hearing.component.html",
  styleUrls: ["./hearing.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class HearingComponent implements OnInit {
  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  Incidents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  //incidents: any[] = [];
  hearings: any[] = [];
  incidentsObservable$: any;
  private subscriptions: Subscription = new Subscription();
  employees: any[] = [];
  transgressions: any[] = [];

  constructor(
    public dialogService: DialogService,
    public incidentService: IncidentService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public employeeService: EmployeeService,
    public transgressionService: TransgressionService,
    public FilesService: FilesService,
    public hearingService: HearingService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.Incidents$.next(false);
    }, 4000);
    this.subscriptions.add(
      this.hearingService.getHearings().subscribe((hearings) => {
        this.hearings = hearings;
      })
    );
    this.loadEmployees(); // Load employees
    this.loadTransgressions(); // Load transgressions
    this.loadHearingsAndIncidents(); // Load hearings and incidents
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onGlobalSearch(event: any) {
    const searchValue = event.target.value;
    if (searchValue.trim()) {
      // Filter the employees array based on the global search query
      this.hearings = this.filterHearings(searchValue);
    } else {
      // If search is cleared, load the full list
      this.getHearingList();
    }
  }

  filterHearings(query: string): any[] {
    return this.hearings.filter((hearing) =>
      Object.values(hearing).some((value) =>
        String(value).toLowerCase().includes(query.toLowerCase())
      )
    );
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }
  loadTransgressions() {
    this.transgressionService
      .getTransgressions()
      .subscribe((transgressions) => {
        this.transgressions = transgressions;
      });
  }

  getEmployeeNameById(id: number): string {
    const employee = this.employees.find((emp: any) => emp.id === id);
    return employee
      ? `${employee.employee_code}  ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  getTransgressionOffenceById(id: number): string {
    const transgression = this.transgressions.find(
      (trans: any) => trans.id === id
    );
    return transgression ? `${transgression.offence}` : "loading...";
  }

  hearingT: Hearing[] = [];
  incidents: any[] = [];

  // loadHearingsAndIncidents() {
  //   forkJoin([
  //     this.incidentService.getIncidents(),
  //     this.hearingService.getHearings(),
  //   ]).subscribe(([incidents, hearingT]) => {
  //     this.incidents = incidents;
  //     this.hearings = hearingT.map((hearing) => {
  //       const incident = this.incidents.find(
  //         (inc) => inc.id === hearing.incident_id
  //       );
  //       const employee = incident
  //         ? `${incident.employee.employee_code} ${incident.employee.first_name} ${incident.employee.last_name}`
  //         : "Unknown Employee";
  //       const transgression = incident
  //         ? incident.transgression.offence
  //         : "No Transgression";
  //       const description = incident ? incident.description : "No Description";

  //       return {
  //         ...hearing,
  //         incidentDetails: `${employee} - ${description} - ${transgression}`,
  //       };
  //     });
  //   });
  // }

  loadHearingsAndIncidents() {
    this.incidentService.getIncidents().subscribe((incidents) => {
      this.incidents = incidents;
      this.hearingService.getHearings().subscribe((hearingT: Hearing[]) => {
        this.hearings = hearingT.map((hearing) => {
          const incident = this.incidents.find(
            (inc) => inc.id === hearing.incident_id
          );
          const employee = incident
            ? `${incident.employee.employee_code} ${incident.employee.first_name} ${incident.employee.last_name}`
            : "Unknown Employee";
          const transgression = incident
            ? incident.transgression.offence
            : "No Transgression";
          const description = incident
            ? incident.description
            : "No Description";

          return {
            ...hearing,
            incidentDetails: `${employee} - ${description} - ${transgression}`,
          };
        });
      });
    });
  }
  private refreshIncidentData() {
    this.getHearingList();
  }

  // getIncidentsOptionsList() {
  //   this.subscriptions.add(
  //     this.incidentService.getIncidents().subscribe((incidents: any[]) => {
  //       this.incidents = incidents;
  //     })
  //   );
  // }
  getHearingList() {
    this.subscriptions.add(
      this.hearingService.getHearings().subscribe((hearings: any[]) => {
        this.hearings = hearings;
      })
    );
  }

  getHearingStatusSeverity(value: string): string {
    switch (value) {
      case "open":
        return "success";
      case "terminated":
        return "danger";
      case "pending hearing":
        return "danger";
      case "dismissed_hearing":
        return "info";
      default:
        return "info";
    }
  }

  columns = [
    { field: "hearing_status", header: "Hearing Status" },
    { field: "incident_id", header: "Incident(Employee details & offence) " },
    { field: "action_taken", header: "Action Taken" },
    { field: "resolution", header: "Resolution" },
    { field: "venue", header: "Venue" },
    { field: "hearing_date", header: "Hearing Date" },
  ];

  // shouldShowActionTaken(incident: any): boolean {
  //   return (
  //     incident["action_taken"] === "Notice of Hearing + Potential Termination"
  //   );
  // }

  // sendSmsNotification(hearingId: number): void {
  //   this.employeeService.sendSms(hearingId).subscribe(
  //     (response) => {
  //       // // Display success message
  //       // this.messageService.add({
  //       //   severity: "success",
  //       //   summary: "Success",
  //       //   detail: "SMS sent successfully",
  //       // });
  //     },
  //     (error) => {
  //       // Display error message
  //       this.messageService.add({
  //         severity: "error",
  //         summary: "Error",
  //         detail:
  //           error?.message ||
  //           "Failed to send SMS notification, Please check hearing details",
  //       });
  //     }
  //   );
  // }
  sendSmsNotification(
    hearingId: number,
    hearingDate: Date,
    venue: string
  ): void {
    this.employeeService.sendSms(hearingId, hearingDate, venue).subscribe(
      (response) => {
        // Display success message
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "SMS sent successfully",
        });
      },
      (error) => {
        // Display error message
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "Failed to send SMS notification",
        });
      }
    );
  }

  isLastElement(file: any, filesArray: any[]): boolean {
    return filesArray.indexOf(file) === filesArray.length - 1;
  }

  viewHearing(rowDatas: any) {
    this.refreshIncidentData();
    this.ref = this.dialogService.open(ViewHearingComponent, {
      data: {
        hearing: rowDatas,
      },
      header: "Hearing Details",
      width: "90%",
      height: "70%",
      closeOnEscape: true,
    });
    this.loadHearingsAndIncidents();
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getHearingList();
        this.loadHearingsAndIncidents();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Hearing successfully concluded",
        });
      }
    });
  }

  showAdd() {
    const hearingOptions = this.hearings.map((hearings) => ({
      id: hearings.id,
    }));

    this.ref = this.dialogService.open(CreateHearingComponent, {
      data: {
        hearingOptions: hearingOptions,
      },
      header: "Schedule Hearing",
      width: "65%",
      height: "70%",
      closeOnEscape: true,
    });
    this.loadHearingsAndIncidents();
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getHearingList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Hearing successfully scheduled",
        });
        this.loadHearingsAndIncidents();
      }
    });
  }

  editHearing(rowData: any) {
    this.refreshIncidentData();
    this.ref = this.dialogService.open(CreateHearingComponent, {
      data: {
        formData: rowData,
      },
      header: "Edit Hearing",
      width: "90%",
      height: "70%",
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        console.log("rowdata", rowData);
        const hearingId = rowData.id;

        // Call the update service to update the hearing
        this.hearingService.updateHearing(hearingId, result).subscribe(() => {
          // Refresh the hearing list
          this.getHearingList();

          // Display success message
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Hearing updated successfully",
          });
          this.loadHearingsAndIncidents();
        });
      } else {
        // Toast message for closing the dialog without saving
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Editing hearing was cancelled",
        });
        this.loadHearingsAndIncidents();
      }
    });
  }

  deleteIncident(rowData: any) {
    //   this.confirmationService.confirm({
    //     message: "Are you sure you want to delete this incident?",
    //     header: "Delete Confirmation",
    //     icon: "pi pi-info-circle",
    //     accept: () => {
    //       this.incidentService.deleteIncident(rowData.id).subscribe(() => {
    //         this.getIncidentsOptionsList();
    //         this.messageService.add({
    //           severity: "success",
    //           summary: "Success",
    //           detail: "Incident deleted successfully",
    //         });
    //       });
    //     },
    //     reject: () => {
    //       this.messageService.add({
    //         severity: "info",
    //         summary: "Rejected",
    //         detail: "Incident was not deleted",
    //       });
    //     },
    //   });
  }
}
