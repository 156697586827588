<div
  *ngIf="this.createEmployees$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
  </div>
</div>

<ng-template #loadedContent>
  <form [formGroup]="employeeForm" (ngSubmit)="onAddEmployeeFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeeCode">Employee Code</label>
          <input
            formControlName="employee_code"
            type="text"
            class="form-control"
            id="employeeCode"
            placeholder="Enter employee's code"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              employeeForm.get('employee_code')?.invalid &&
              (employeeForm.get('employee_code')?.touched ||
                employeeForm.get('employee_code')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Employee Code</strong> is required</small
            >
          </div>
        </div>
        <!---
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employee_code">Employee code</label>
          <input
            formControlName="employee_code"
            type="text"
            class="form-control"
            id="employee_code"
            placeholder="Enter employee's code"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="employeeForm.get('employee_code')?.invalid && (employeeForm.get('employee_code')?.touched || employeeForm.get('employee_code')?.dirty)"
            class="px-1 py-1"
          >
            <small class="p-error block"><strong>First Name</strong> is required</small>
          </div>
        </div>
        -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeeFirstName">First Name</label>
          <input
            formControlName="first_name"
            type="text"
            class="form-control"
            id="employeeFirstName"
            placeholder="Enter employee's first name"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              employeeForm.get('first_name')?.invalid &&
              (employeeForm.get('first_name')?.touched ||
                employeeForm.get('first_name')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>First Name</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeeLastName">Last Name</label>
          <input
            formControlName="last_name"
            type="text"
            class="form-control"
            id="employeeLastName"
            placeholder="Enter employee's last name"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              employeeForm.get('last_name')?.invalid &&
              (employeeForm.get('last_name')?.touched ||
                employeeForm.get('last_name')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Last Name</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeePosition">Position</label>
          <input
            formControlName="position"
            type="text"
            class="form-control"
            id="employeePosition"
            placeholder="Enter employee's position"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              employeeForm.get('position')?.invalid &&
              (employeeForm.get('position')?.touched ||
                employeeForm.get('position')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Position</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeeDepartment">Department</label>
          <input
            formControlName="department"
            type="text"
            class="form-control"
            id="employeeDepartment"
            placeholder="Enter employee's department"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              employeeForm.get('department')?.invalid &&
              (employeeForm.get('department')?.touched ||
                employeeForm.get('department')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Department</strong> is required</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeeEmail">Email</label>
          <input
            formControlName="email"
            type="email"
            class="form-control"
            id="employeeEmail"
            placeholder="Enter employee's email"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              employeeForm.get('email')?.invalid &&
              (employeeForm.get('email')?.touched ||
                employeeForm.get('email')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Email</strong> is required and must be a valid email
              address</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeePhoneNumber">Phone Number</label>
          <input
            formControlName="phone_number"
            type="text"
            class="form-control"
            id="employeePhoneNumber"
            placeholder="Enter employee's phone number"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              employeeForm.get('phone_number')?.invalid &&
              (employeeForm.get('phone_number')?.touched ||
                employeeForm.get('phone_number')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Phone Number</strong> is required and must be a valid
              number</small
            >
          </div>
        </div>
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employeeStatus">Status</label>
          <input
            formControlName="status"
            type="text"
            class="form-control"
            id="employeeStatus"
            placeholder="Enter employee's status"
            pInputTextarea
            rows="5"
            cols="30"
            readonly
            style="
              pointer-events: none;
              background-color: #f8f9fa;
              color: #495057;
            "
          />
          <div
            *ngIf="
              employeeForm.get('status')?.invalid &&
              (employeeForm.get('status')?.touched ||
                employeeForm.get('status')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Status</strong> is required</small
            >
          </div>
        </div>
        <div class="field col-12">
          <p-button
            styleClass="bg-blue-400"
            type="submit"
            label="Submit"
            class="w-6"
          ></p-button>
             <!-- [loading]="loading" -->
        </div>
      </div>
    </div>
  </form>
</ng-template>
