import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, map, switchMap, tap } from "rxjs";
import { LeaveBalance } from "../_models/leaveBalance";
import { ApiService } from "src/app/_core/_services/api.service";
import { HttpClient } from "@angular/common/http";
import { Employee } from "../_models/employee";
import { EmployeeService } from "./employee.service";

@Injectable({
  providedIn: "root",
})
export class LeaveBalanceService {
  private LeaveBalances: LeaveBalance[] = [];
  private LeaveBalances$ = new BehaviorSubject<LeaveBalance[]>([]);

  constructor(
    public api: ApiService,
    private http: HttpClient,
    private employeeService: EmployeeService
  ) {}

  // Get leave balances and filter out terminated employees
  getLeaveBalances(): Observable<LeaveBalance[]> {
    return this.api.getAPI<LeaveBalance[]>("leave-balances").pipe(
      switchMap((res) => {
        const leaveBalances = res.body;
        return this.employeeService.getEmployees().pipe(
          map((employees) => {
            // Filter out employees who are terminated
            const activeEmployeeIds = employees
              .filter(
                (employee) => employee.status?.toLowerCase() !== "terminated"
              )
              .map((employee) => employee.id);

            // Filter leave balances based on the active employee IDs
            return leaveBalances.filter((leaveBalance: LeaveBalance) =>
              activeEmployeeIds.includes(leaveBalance.employee_id)
            );
          })
        );
      }),
      tap((filteredLeaveBalances) =>
        this.LeaveBalances$.next(filteredLeaveBalances)
      )
    );
  }

  // Get leave balances for a specific employee and ensure the employee is not terminated
  getLeaveBalanceByEmployeeId(employeeId: number): Observable<LeaveBalance[]> {
    return this.employeeService.getEmployees().pipe(
      switchMap((employees) => {
        const employee = employees.find(
          (e) => e.id === employeeId && e.status?.toLowerCase() !== "terminated"
        );
        if (employee) {
          return this.api
            .getAPI<LeaveBalance[]>(`leave-balances/employee/${employeeId}`)
            .pipe(
              map((res) => {
                console.log("Leaves balance by employee", res.body.data);
                return res.body.data;
              })
            );
        } else {
          // If the employee is terminated, return an empty array
          return new BehaviorSubject<LeaveBalance[]>([]).asObservable();
        }
      })
    );
  }

  // getLeaveBalances(): Observable<LeaveBalance[]> {
  //   return this.api.getAPI<LeaveBalance[]>("leave-balances").pipe(
  //     map((res) =>
  //       res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
  //     ),
  //     tap((LeaveBalance) => this.LeaveBalances$.next(LeaveBalance))
  //   );
  // }

  // getLeaveBalanceByEmployeeId(employeeId: number): Observable<LeaveBalance[]> {
  //   return this.api
  //     .getAPI<LeaveBalance[]>(`leave-balances/employee/${employeeId}`)
  //     .pipe(
  //       map((res) => {
  //         console.log("Leaves balance by employee", res.body.data);
  //         return res.body.data;
  //       })
  //     );
  // }

  addLeaveBalance(newLeave: LeaveBalance): Observable<any> {
    return this.api
      .postAPI<LeaveBalance>("leave_balances/create", newLeave)
      .pipe(
        tap((res: any) => {
          console.log("leave added", res);
        }),
        switchMap(() => this.getLeaveBalances())
      );
  }

  updateLeaveBalance(
    leaveId: number,
    leavesData: LeaveBalance
  ): Observable<any> {
    return this.api
      .patchAPI<LeaveBalance>("leave_balances/update", leaveId, leavesData)
      .pipe(
        tap((res: any) => {
          console.log("leave updated", res);
        }),
        switchMap(() => this.getLeaveBalances())
      );
  }

  deleteLeaveBalance(leaveId: number): Observable<any> {
    return this.api
      .deleteAPI<any>("leave_balances/delete", leaveId)
      .pipe(tap(() => this.refreshLeaveBalance()));
  }
  refreshLeaveBalance(): Observable<LeaveBalance[]> {
    return this.getLeaveBalances().pipe(
      tap((LeaveBalances) => {
        this.LeaveBalances = LeaveBalances;
        this.LeaveBalances$.next(this.LeaveBalances);
      })
    );
  }
  get leavesObservable$(): Observable<LeaveBalance[]> {
    return this.LeaveBalances$.asObservable();
  }
}
