<div
  *ngIf="this.createSuspensions$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>

<ng-template #loadedContent>
  <form [formGroup]="suspensionForm" (ngSubmit)="onSuspensionFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employee_id">Employee</label>
          <p-dropdown
            [options]="employees"
            optionLabel="label"
            optionValue="value"
            formControlName="employee_id"
            placeholder="Select an employee"
            [filter]="true"
            filterPlaceholder="Search employees by code or name"
          ></p-dropdown>
          <div
            *ngIf="
              suspensionForm.get('employee_id')?.invalid &&
              (suspensionForm.get('employee_id')?.touched ||
                suspensionForm.get('employee_id')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Employee ID</strong> is required</small
            >
          </div>
        </div>

        <div class="field mb-4 col-12 lg:col-6">
          <label for="suspensionStatus">Suspension Status</label>
          <p-dropdown
            formControlName="suspension_status"
            [options]="suspensionStatusOptions"
            placeholder="Select suspension status"
            id="suspensionStatus"
          ></p-dropdown>
          <div
            *ngIf="
              suspensionForm.get('suspension_status')?.invalid &&
              (suspensionForm.get('suspension_status')?.touched ||
                suspensionForm.get('suspension_status')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Suspension Status</strong> is required</small
            >
          </div>
        </div>

        <!-- Suspension Start Date Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="start_date">Suspension Start Date</label>
          <p-calendar
            [showIcon]="true" 
            type="text"
            id="start_date"
            name="start_date"
            class="w-full"
            formControlName="start_date"
            dateFormat="yy-mm-dd"
            [inline]="false"
            dataType="string"
            appendTo="body"
          ></p-calendar>
        </div>

        <!-- Suspension End Date Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="end_date">Suspension End Date</label>
          <p-calendar
            [showIcon]="true" 
            type="text"
            id="end_date"
            name="end_date"
            class="w-full"
            formControlName="end_date"
            dateFormat="yy-mm-dd"
            [inline]="false"
            dataType="string"
            appendTo="body"
          ></p-calendar>
        </div>

        <!-- Suspension Reason Field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="reason">Suspension Reason</label>
          <textarea
            formControlName="reason"
            type="text"
            class="form-control"
            id="reason"
            placeholder="Enter suspension reason"
            pInputTextarea
            [autoResize]="true"
            rows="5"
            cols="30"
            class="form-control p-inputtext p-component"
          ></textarea>
          <div
            *ngIf="
              suspensionForm.get('reason')?.invalid &&
              (suspensionForm.get('reason')?.touched ||
                suspensionForm.get('reason')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Suspension Reason</strong> is required</small
            >
          </div>
        </div>

        <div class="field col-12">
          <p-button
            styleClass="bg-blue-400"
            type="submit"
            label="Submit"
            class="w-6"
          ></p-button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
