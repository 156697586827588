import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Leave } from "src/app/_share/_models/leave";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { LeaveBalance } from "src/app/_share/_models/leaveBalance";
import { LeaveBalanceService } from "src/app/_share/_services/leaveBalance.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";

@Component({
  selector: "app-create-leave-balance",
  templateUrl: "./create-leave-balance.component.html",
  styleUrls: ["./create-leave-balance.component.scss"],
})
export class CreateLeaveBalanceComponent implements OnInit {
  createLeaveBalances$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  leaveForm: FormGroup = this.formBuilder.group({
    employee_id: ["", Validators.required],
    annual_leave_balance: ["", Validators.required],
    sick_leave_balance: ["", Validators.required],
    maternity_leave_balance: ["", Validators.required],
    unpaid_leave_balance: ["", Validators.required],
    family_responsiblity_leave_amount: ["", Validators.required],
    study_leave_balance: ["", Validators.required],
  });

  loading: boolean = false;
  employees: any[] = [];
  editFormData: Leave | undefined;
  isInEditMode: boolean = false;

  editFormGroups: any;
  fb: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public leaveService: LeaveService,
    public leavebalance: LeaveBalanceService,
    public formBuilder: FormBuilder,
    public employeeService: EmployeeService
  ) {}

  ngOnInit(): void {
    // Simulate loading completion
    setTimeout(() => {
      this.createLeaveBalances$.next(false);
    }, 1000);
    this.updateFormData();
    this.loadEmployees();
  }

  // loadEmployees() {
  //   this.employeeService.getEmployees().subscribe((employees) => {
  //     this.employees = employees.map((employee: any) => ({
  //       label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
  //       value: employee.id,
  //     }));
  //   });
  // }
  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.leavebalance.getLeaveBalances().subscribe((leaveBalances) => {
        // Create a Set of employee IDs with existing leave balances
        const employeesWithLeaveBalance = new Set(
          leaveBalances.map(
            (leaveBalance: LeaveBalance) => leaveBalance.employee_id
          )
        );

        // If in edit mode, ensure the current employee is included
        if (this.isInEditMode && this.editFormData) {
          employeesWithLeaveBalance.delete(this.editFormData.employee_id);
        }

        // Filter out employees who already have a leave balance
        const filteredEmployees = employees.filter(
          (employee: any) =>
            !employeesWithLeaveBalance.has(employee.id) &&
            employee.status !== "terminated"
        );

        // Update the employee dropdown list with filtered employees
        this.employees = filteredEmployees.map((employee: any) => ({
          label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
          value: employee.id,
        }));
      });
    });
  }

  //Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data?.formdata;
    if (this.editFormData) {
      if (Array.isArray(this.editFormData)) {
        if (this.editFormData.length > 0) {
          this.editFormData.forEach((desc: any, index: number) => {
            const formGroup = this.fb.group({
              employee_id: desc.employee_id,
              annual_leave_balance: desc.annual_leave_balance,
              sick_leave_balance: desc.sick_leave_balance,
              maternity_leave_balance: desc.maternity_leave_balance,
              unpaid_leave_balance: desc.unpaid_leave_balance,
              family_responsiblity_leave_amount:
                desc.family_responsiblity_leave_amount,
              study_leave_balance: desc.study_leave_balance,
            });
            this.editFormGroups.push(formGroup);
            this.isInEditMode = true;
          });
        }
      } else {
        this.leaveForm.patchValue(this.editFormData);
        this.isInEditMode = true;
      }
    } else {
      this.isInEditMode = false;
    }
  }

  onAddleaveFormSubmit() {
    this.loading = true;
    if (this.leaveForm.invalid) {
      this.leaveForm.markAllAsTouched();
      return;
    }
    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      // called .value on form data after assigning form values to updatedFormData, thus you were trying to extract this.leaveForm.value.value effectively
      this.leavebalance
        .updateLeaveBalance(this.editFormData.id, this.leaveForm.value)
        .subscribe({
          next: (res: any) => {
            console.log("Leave updated successfully", res);
            this.ref.close(this.leaveForm.value);
            this.refreshData(); // Refresh data after successful update
          },
          error: (err: any) => {
            console.error("Error updating leave", err);
            this.loading = false; // Stop loading on error
          },
        });
    } else {
      this.leavebalance.addLeaveBalance(this.leaveForm.value).subscribe({
        next: (res: any) => {
          console.log("Leave balance added successfully", res);
          this.leavebalance.refreshLeaveBalance();
          this.ref.close(this.leaveForm.value);
          this.refreshData(); // Refresh data after successful update
        },
        error: (err: any) => {
          console.error("Error adding leave", err);
          this.loading = false; // Stop loading on error
        },
      });
    }
  }
  // Add a new method to refresh data
  refreshData() {
    this.loadEmployees(); // Reload employees to update the dropdown
    this.loading = false; // Stop loading after refresh
  }
}
