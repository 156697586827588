import { Component, OnInit } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { StatisticsService } from "src/app/_share/_services/statistics.service";

@Component({
  selector: "app-statistics-bar",
  templateUrl: "./statistics-bar.component.html",
  styleUrls: ["./statistics-bar.component.scss"],
})
export class StatisticsBarComponent implements OnInit {
  incidents: any;
  hearings: any;
  employees: any;
  transgressions: any;
  suspendedEmployees: any;

  suspendedEmployeesToday$: any[] = [];
  allSuspendedEmployees$: any[] = [];
  today: Date = new Date();

  incidents$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  hearings$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  employees$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  transgressions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  suspendedEmployees$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  constructor(private ss: StatisticsService) {}

  // Method to calculate days since suspension
  getDaysSinceSuspended(suspensionDate: string): number {
    const suspension = new Date(suspensionDate);
    const currentDate = new Date();
    const timeDiff = currentDate.getTime() - suspension.getTime();
    const daysSuspended = Math.floor(timeDiff / (1000 * 3600 * 24));
    // If the suspension happened today, treat it as 0 days
    return daysSuspended < 1 ? 0 : daysSuspended;
  }

  groupEmployeesByStatus() {
    const suspendedEmployees = this.suspendedEmployees$.getValue();
    // Filter only suspended employees, and exclude those suspended for 0 days in the "All Suspended Employees" section
    this.allSuspendedEmployees$ = suspendedEmployees
      .filter(
        (employee) =>
          employee.status.toLowerCase() === "suspended" ||
          employee.status.toLowerCase() === "unpaid suspension"
      )
      .filter(
        (employee) => this.getDaysSinceSuspended(employee.suspension_date) > 0
      ); // Exclude 0 days
    // Group employees suspended today
    const todayStr = this.today.toISOString().split("T")[0]; // "YYYY-MM-DD"
    this.suspendedEmployeesToday$ = suspendedEmployees
      .filter(
        (employee) =>
          employee.status.toLowerCase() === "suspended" ||
          employee.status.toLowerCase() === "unpaid suspension"
      )
      .filter(
        (employee) =>
          new Date(employee.suspension_date).toISOString().split("T")[0] ===
          todayStr
      ); // Include 0 days suspended
  }

  ngOnInit(): void {
    this.ss.getEmployeeStatistics().subscribe((res) => {
      console.log(res);
      this.employees = res;
      this.employees$.next(res);
    });
    this.ss.getIncidentStatistics().subscribe((res) => {
      console.log(res);
      this.incidents = res;
      this.incidents$.next(res);
    });
    this.ss.getTransgressionStatistics().subscribe((res) => {
      this.transgressions = res;
      this.transgressions$.next(res);
    });
    this.ss.getHearingStatistics().subscribe((res) => {
      this.hearings = res;
      this.hearings$.next(res);
    });
    // Fetch suspended employees
    this.ss.getSuspendedEmployeesStatistics().subscribe((res) => {
      this.suspendedEmployees$.next(res.suspended_employees);
      this.groupEmployeesByStatus();
    });
  }
}
