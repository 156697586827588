import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Incident } from "src/app/_share/_models/incident";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { FilesService } from "src/app/_share/_services/files.service";
import { FileUpload } from "primeng/fileupload";
import { forkJoin } from "rxjs";
import { Console } from "console";

@Component({
  selector: "app-create-incident",
  templateUrl: "./create-incident.component.html",
  styleUrls: ["./create-incident.component.scss"],
})
export class CreateIncidentComponent implements OnInit {
  createIncidents$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  @ViewChild("fubadvanced") fubadvanced!: FileUpload;
  incidentForm: FormGroup = this.formBuilder.group({
    category: [[]],
    description: ["", Validators.required],
    action_taken: ["open incident", { disabled: true }], // Disabled and with default value
    date: ["", Validators.required],
    resolution: [false, Validators.required],
    employee_id: [null, [Validators.required, Validators.pattern("^[0-9]*$")]],
    // transgression_id: [
    //   "",
    //   [Validators.required, Validators.pattern("^[0-9]*$")],
    // ],
    transgression_ids: [[], Validators.minLength(1)],
  });

  editFormData: Incident | undefined;
  isInEditMode: boolean = false;
  employees: any[] = [];
  transgressions: any[] = [];
  resolutionOptions: any[];
  evidencesFiles: any[] = [];
  generatedFiles: any[] = [];
  adminFiles: any[] = [];
  loading: boolean = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public incidentService: IncidentService,
    public employeeService: EmployeeService,
    public transgressionService: TransgressionService,
    public formBuilder: FormBuilder,
    public filesService: FilesService
  ) {
    this.resolutionOptions = [
      { label: "True", value: true },
      { label: "False", value: false },
    ];
  }
  //   this.resolutionOptions = [
  //     { label: "Guilty", value: true },
  //     { label: "Under Investigation", value: false },
  //   ];
  // }

  ngOnInit(): void {
    this.editFormData = this.config.data?.formData;
    console.log(this.editFormData);

    setTimeout(() => {
      this.createIncidents$.next(false);
    }, 1000);
    this.loadEmployees();
    this.loadTransgressions();
    this.updateFormData();
    // Update category when transgression changes
    this.incidentForm
      .get("transgression_ids")
      ?.valueChanges.subscribe((value) => {
        console.log("x", value);
        // this.updateCategory(value);
      });
    // Update category when transgression_id changes
    this.incidentForm
      .get("transgression_ids")
      ?.valueChanges.subscribe((value) => {
        console.log("Transgression ID selected:", value);
        this.updateCategory(value); // Update category whenever the transgression changes
      });
    this.filterFilesByType();
    console.log("evidencesFiles", this.evidencesFiles);
    console.log("generatedFiles", this.generatedFiles);
    console.log("adminFiles", this.adminFiles);
  }
  incident: any;
  getFileLinks(files: any[]): any[] {
    if (!files || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      unique_file_reference: file.unique_file_reference,
      original_filename: file.original_filename,
    }));
  }

  filterFilesByType() {
    if (this.incident && this.incident.files) {
      this.evidencesFiles = this.incident.files.filter(
        (file: any) => file.file_association_type === "evidence"
      );
      this.generatedFiles = this.incident.files.filter(
        (file: any) => file.file_association_type === "generated"
      );
      this.adminFiles = this.incident.files.filter(
        (file: any) => file.file_association_type === "admin"
      );
    }
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.filesService
      .downloadFile(unique_file_reference, original_filename)
      .subscribe(
        () => {
          console.log("File downloaded successfully");
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees.map((employee: any) => ({
        label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
        value: employee.id,
      }));
    });
  }

  loadTransgressions() {
    this.transgressionService
      .getTransgressions()
      .subscribe((transgressions) => {
        this.transgressions = transgressions.map((transgression: any) => ({
          // label: `${transgression.category} - ${transgression.offence}`,
          // this turns category into code
          // .replace("Category", "Code")
          label: `${transgression.disciplinary_code} - ${transgression.standard} - ${transgression.offence}`,
          // ${transgression.category},
          value: transgression.id,
          category: transgression.category,
        }));
      });
  }

  // xupdateCategory(transgressionId: number) {
  //   const selectedTransgression = this.transgressions.find(
  //     (transgression) => transgression.value === transgressionId
  //   );
  //   if (selectedTransgression) {
  //     this.incidentForm.patchValue({
  //       category: selectedTransgression.category,
  //     });
  //   }
  // }

  updateCategory(transgressionIds: number | number[]) {
    const selectedTransgressionId = Array.isArray(transgressionIds)
      ? transgressionIds[0] // Take the first transgression ID if it's an array (in create mode)
      : transgressionIds; // Directly use the ID if it's a single value (in edit mode)

    const selectedTransgression = this.transgressions.find(
      (transgression) => transgression.value === selectedTransgressionId
    );

    if (selectedTransgression) {
      this.incidentForm.patchValue({
        category: selectedTransgression.category, // Set the category based on the selected transgression
      });
    }
  }

  //works
  updateFormData() {
    if (this.editFormData) {
      this.incident = this.editFormData;

      // If the incident has a transgression_id, set it as a single value for editing
      if (this.incident.transgression_id) {
        this.incidentForm.patchValue({
          transgression_ids: this.incident.transgression_id, // Set the single transgression ID directly
        });
      }

      // Patch the rest of the form data
      this.incidentForm.patchValue(this.incident);
      this.isInEditMode = true;
    } else {
      this.isInEditMode = false;
    }
  }

  uploadedFiles: any[] = [];

  //not implemented
  handleFileData(file: File) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      console.log(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  onFileUpload(event$: any, uploader: FileUpload) {
    for (let file of event$.files) {
      let formData = new FormData();
      formData.append("file", file);
      if (!this.isInEditMode) {
        this.filesService.uploadFile(formData, 0).subscribe({
          next: (response: any) => {
            console.log("File uploaded successfully", response);
            // Store uploaded file data for later use
            const uploadedFileData = response.body.data; // Capture the response data
            this.uploadedFiles.push(uploadedFileData);
          },
          error: (err: any) => {
            console.error("Error uploading file", err);
          },
        });
      } else {
        this.filesService.uploadFile(formData, this.incident.id).subscribe({
          next: (response: any) => {
            console.log("File uploaded successfully", response);
            // Store uploaded file data for later use
            const uploadedFileData = response.body.data; // Capture the response data
            this.uploadedFiles.push(uploadedFileData);
            this.incidentService.updateIncident(
              this.incident.id,
              this.incident
            );
          },
          error: (err: any) => {
            console.error("Error uploading file", err);
          },
        });
      }
    }
    // Clear the file list in the uploader
    uploader.clear();
  }

  downloadWarningDocument(incidentId: number) {
    this.filesService.downloadGeneratedHearingFile(incidentId).subscribe(
      (response: any) => {
        console.log("response received:", response);

        let defaultFileName = `WrittenWarning_${incidentId}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  downloadGeneratedFile(file_id: number, incident_id: number) {
    this.filesService.downloadGeneratedFile(file_id, incident_id).subscribe(
      (response: any) => {
        console.log("Response received:", response);

        let defaultFileName = `HearingDocument_${incident_id}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1]
              .replace(/['"]/g, "")
              .replace("utf-8", "")
              .replace(/%20/g, "_")
              .trim(); // Replace %20 with underscores
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }

        // Create a blob and trigger the download
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Use the sanitized filename
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  onAddIncidentFormSubmit() {
    this.loading = true;

    if (this.incidentForm.invalid) {
      this.incidentForm.markAllAsTouched();
      this.loading = false;
      return;
    }

    const { transgression_ids, category, ...commonIncidentData } =
      this.incidentForm.value;

    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      // Handle edit mode
      const updatedIncidentData: Incident = {
        ...commonIncidentData,
        transgression_id: Array.isArray(transgression_ids)
          ? transgression_ids[0]
          : transgression_ids, // Ensure single ID for edit mode
        category: category, // This will automatically be updated based on transgression selection
        files: this.uploadedFiles, // Attach uploaded files
      };
      this.incidentService
        .updateIncident(this.editFormData.id, updatedIncidentData)
        .subscribe({
          next: (res: any) => {
            console.log("Incident updated successfully", res);
            this.incidentService.refreshIncidents();
            this.ref.close(this.incidentForm.value);
            this.loading = false;
          },
          error: (err: any) => {
            console.error("Error updating incident", err);
            this.loading = false;
          },
        });
    } else {
      // Handle new incidents (multiple transgressions selected)
      const incidentRequests = transgression_ids.map(
        (transgressionId: number) => {
          const transgression = this.transgressions.find(
            (transgression) => transgression.value === transgressionId
          );
          const incidentData: Incident = {
            ...commonIncidentData,
            transgression_id: transgressionId,
            category: transgression?.category, // Ensure category is correctly set for each incident
            files: this.uploadedFiles, // Attach uploaded files
          };
          return this.incidentService.addIncident(incidentData);
        }
      );

      forkJoin(incidentRequests).subscribe({
        next: (responses: any) => {
          console.log("All incidents added successfully", responses);
          this.incidentService.refreshIncidents();
          this.ref.close(this.incidentForm.value);
          this.loading = false;
        },
        error: (err: any) => {
          console.error("Error adding incidents", err);
          this.loading = false;
        },
      });
    }
  }
  // xonAddIncidentFormSubmit() {
  //   this.loading = true;
  //   if (this.incidentForm.invalid) {
  //     this.incidentForm.markAllAsTouched();
  //     this.loading = false;
  //     return;
  //   }

  //   const incidentData: Incident = {
  //     ...this.incidentForm.value,
  //     files: this.uploadedFiles, // Add the uploaded files to the incident data
  //   };

  //   if (this.isInEditMode && this.editFormData?.id !== undefined) {
  //     this.incidentService
  //       //this.incidentForm.value
  //       .updateIncident(this.editFormData.id, incidentData)
  //       .subscribe({
  //         next: (res: any) => {
  //           console.log("Incident updated successfully", res);
  //           this.incidentService.refreshIncidents();
  //           this.ref.close(this.incidentForm.value);
  //           this.loading = false;
  //         },
  //         error: (err: any) => {
  //           console.error("Error updating incident", err);
  //         },
  //       });
  //   } else {
  //     //this.incidentForm.value
  //     this.incidentService.addIncident(incidentData).subscribe({
  //       next: (res: any) => {
  //         console.log("Incident added successfully", res);
  //         this.incidentService.refreshIncidents();
  //         this.ref.close(this.incidentForm.value);
  //       },
  //       error: (err: any) => {
  //         console.error("Error adding incident", err);
  //       },
  //     });
  //   }
  // }
}
