import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject, Subscription, switchMap } from "rxjs";
import { Incident } from "src/app/_share/_models/incident";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { FilesService } from "src/app/_share/_services/files.service";
import { Leave } from "src/app/_share/_models/leave";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { FileUpload } from "primeng/fileupload";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
  selector: "app-view-leave",
  templateUrl: "./view-leave.component.html",
  styleUrls: ["./view-leave.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class ViewLeaveComponent implements OnInit {
  leaves$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  leave: Leave = this.config.data.leave;
  employees: any[] = [];
  evidencesFiles: any[] = [];
  generatedFiles: any[] = [];
  adminFiles: any[] = [];
  resolutionOptions: any[];

  constructor(
    public ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private leaveService: LeaveService, // private incidentService: IncidentService
    public filesService: FilesService,
    public formBuilder: FormBuilder,
    public employeeService: EmployeeService,
    public messageService: MessageService
  ) {
    this.resolutionOptions = [
      { label: "Approved", value: "approved" },
      { label: "Denied", value: "denied" },
    ];
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }

  getEmployeeNameById(id: number): string {
    // Find employee by ID and return their full name
    const employee = this.employees.find((emp) => emp.id === id);
    return employee
      ? `${employee.employee_code} ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  ngOnInit(): void {
    this.loadEmployees();
    this.leave = this.config.data.leave;
    this.filterFilesByType();
    setTimeout(() => {
      this.leaves$.next(false); // Set loading to false after data is loaded
    }, 2000); // Adjust the timeout as necessary
  }

  ngOnDestroy(): void {}

  filterFilesByType() {
    if (this.leave && this.leave.files) {
      this.evidencesFiles = this.leave.files.filter(
        (file) => file.file_association_type === "evidence"
      );
      this.generatedFiles = this.leave.files.filter(
        (file) => file.file_association_type === "generated"
      );
      this.adminFiles = this.leave.files.filter(
        (file) => file.file_association_type === "admin"
      );
    }
  }

  leaveForm: FormGroup = this.formBuilder.group({
    resolution: ["", Validators.required],
  });

  getFileLinks(files: any[]): any[] {
    if (!files || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      unique_file_reference: file.unique_file_reference,
      original_filename: file.original_filename,
    }));
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.filesService
      .downloadFile(unique_file_reference, original_filename)
      .subscribe(
        () => {
          console.log("File downloaded successfully");
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }
  uploadedFiles: any[] = [];
  onFileUpload(event$: any, uploader: FileUpload) {
    for (let file of event$.files) {
      let formData = new FormData();
      formData.append("file", file);

      this.filesService.uploadLeaveFile(formData, this.leave.id).subscribe({
        next: (response: any) => {
          console.log("Leave file uploaded successfully", response);
          // Store uploaded file data for later use
          const uploadedFileData = response.body.data; // Capture the response data
          this.uploadedFiles.push(uploadedFileData);
        },
        error: (err: any) => {
          console.error("Error uploading file", err);
        },
      });
    }
    // Clear the file list in the uploader
    uploader.clear();
  }

  downloadWarningDocument(leave_action_id: number) {
    this.filesService.GenerateLeaveDocument(leave_action_id).subscribe(
      (response: any) => {
        console.log("response received:", response);

        let defaultFileName = `WrittenWarning_${leave_action_id}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  downloadGeneratedFile(file_id: number, leave_id: number) {
    this.filesService.downloadGeneratedFile(file_id, leave_id).subscribe(
      (response: any) => {
        console.log("Response received:", response);

        let defaultFileName = `HearingDocument_${leave_id}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1]
              .replace(/['"]/g, "")
              .replace("utf-8", "")
              .replace(/%20/g, "_")
              .trim(); // Replace %20 with underscores
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }

        // Create a blob and trigger the download
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Use the sanitized filename
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  concludeLeaveAction() {
    if (this.leaveForm.invalid) {
      this.leaveForm.markAllAsTouched();
      return;
    }

    const resolution = this.leaveForm.get("resolution")?.value;
    const updatedLeaveAction = {
      ...this.leave,
      // incident_status: "concluded",
      // action_taken: "closed incident",
      leave_status: resolution, // Include the resolution value
    };

    // Update the incident first
    this.leaveService
      .updateleave(this.leave.id, updatedLeaveAction)
      .pipe(
        switchMap(() => {
          // After updating, determine the action
          return this.leaveService.determineAction(this.leave.id);
        })
      )
      .subscribe(
        (response) => {
          // Handle success response
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Leave action concluded succesfully.",
          });

          // Check if the resolution is true and action taken is "Verbal Warning Issued"
          // const actionTaken = response?.action_taken;
          // if (
          //   (resolution === true && actionTaken != "verbal-warning-issued") ||
          //   actionTaken == "hearing-scheduled"
          // ) {
          //   this.downloadWarningDocument(this.leave.id);
          // }
          // Trigger download if resolution is true
          // if (resolution === true) {
          //   this.downloadWarningDocument(this.incident.id);
          // }

          this.ref.close(updatedLeaveAction);
        },
        (error) => {
          // Handle error response
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "There was an error processing the leave action.",
          });
        }
      );
  }
}
