import { Component, OnInit } from "@angular/core";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { Routes } from "@angular/router";
import { LeavesComponent } from "../leaves/leaves.component";
import { LeaveBalancesComponent } from "../leave-balances/leave-balances.component";
import { BehaviorSubject, Subscription } from "rxjs";
import { CalendarOptions } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Leave } from "src/app/_share/_models/leave";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "app-policies",
  templateUrl: "./policies.component.html",
  styleUrls: ["./policies.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class policiesComponent implements OnInit {
  constructor(
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public employeeService: EmployeeService,
    private leaveService: LeaveService,
    private http: HttpClient // Injecting HttpClient
  ) {}
  routes: Routes = [
    {
      path: "policies",
      component: policiesComponent,
    },
    {
      path: "leaves",
      component: LeavesComponent, // Component for managing leaves
    },
    {
      path: "leaveBalances",
      component: LeaveBalancesComponent, // Component for leave balances
    },
    { path: "", redirectTo: "/leaveBalances", pathMatch: "full" }, // Default route
  ];
  selectedDate: Date | null = null;
  leaveDays: Array<{ date: Date; title: string }> = [
    { date: new Date("2025-03-10"), title: "Sick Leave" },
    { date: new Date("2025-03-15"), title: "Annual Leave" },
  ];
  employees: any[] = [];
  policies$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  leavesBalances: any[] = [];
  leavesBalancesObservable$: any;
  private subscriptions: Subscription = new Subscription();
  calendarOptions: CalendarOptions | undefined;
  leaves: Leave[] = [];
  public holidays: any[] = []; // To hold South African holidays

  ngOnInit(): void {
    // this.loadHolidays();
    this.loadEmployees();
    this.leaveService.getLeaves().subscribe((leaves) => {
      // Filter leaves to only include approved ones
      const approvedLeaves = leaves.filter(
        (leave) => leave.leave_status === "approved"
      );

      // Map only approved leaves to include employee details in the calendar events
      const calendarEvents = approvedLeaves.map((leave) => {
        const employeeName = this.getEmployeeNameById(leave.employee_id);
        return {
          title: `${employeeName} -  ${leave.leave_action_type}`,
          start: leave.start_period,
          end: leave.end_period,
          backgroundColor: this.getLeaveTypeColor(
            leave.leave_action_type.toString()
          ),
          textColor: "white",
        };
      });
      setTimeout(() => {
        this.policies$.next(false);
      }, 1000);

      // Update the calendar options with the events
      this.calendarOptions = {
        initialView: "dayGridMonth",
        plugins: [dayGridPlugin, interactionPlugin],
        events: calendarEvents, // Pass the formatted events to FullCalendar
        headerToolbar: {
          left: "prev,next today",
          center: "title",
        },
        height: "680px",
        // dateClick: this.handleDateClick.bind(this),
        // eventClick: this.handleEventClick.bind(this),
      };
    });
  }

  // Method to fetch South African public holidays
  // loadHolidays(): void {
  //   const currentYear = new Date().getFullYear();
  //   const url = `https://date.nager.at/Api/v1/PublicHolidays/${currentYear}/ZA`; // Using template literals to insert the year

  //   this.http.get<any[]>(url).subscribe(
  //     (holidays) => {
  //       this.holidays = holidays.map((holiday) => ({
  //         title: holiday.name,
  //         start: holiday.date, // The date of the holiday
  //         backgroundColor: "red", // Styling for holidays (you can change this)
  //         textColor: "white", // Styling for text color
  //       }));
  //     },
  //     (error) => {
  //       console.error("Error fetching holidays", error);
  //     }
  //   );
  // }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }

  getEmployeeNameById(id: number): string {
    // Find employee by ID and return their full name
    const employee = this.employees.find((emp) => emp.id === id);
    return employee
      ? `${employee.employee_code} ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  // handleDateClick(arg: { dateStr: string }): void {
  //   alert("Date clicked: " + arg.dateStr);
  // }

  // handleEventClick(arg: { event: { title: string } }): void {
  //   alert("Event clicked: " + arg.event.title);
  // }
  getLeaveTypeColor(leaveType: string): string {
    switch (leaveType) {
      case "Sick":
        return "green";
      case "Annual":
        return "red";
      case "Maternity":
        return "light blue";
      case "Study":
        return "blue";
      case "Family Responsibility":
        return "purple";
      case "Unpaid":
        return "orange";
      default:
        return "gray";
    }
  }
}
