<div *ngIf="hearings$ | async; else loadedContent">
  <!-- Loading skeleton -->
  <div class="flex p-3 flex-column">
    <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
      <div class="field col-12">
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
      </div>
    </div>
  </div>
</div>

<!-- Loaded content -->
<ng-template #loadedContent>
  <div class="border-round">
    <div class="grid grid-nogutter">
      <div class="col-12 pt-3 px-3">
        <div
          class="text-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Hearing Details
        </div>
      </div>
      <div class="flex flex-row justify-content-between">
        <div class="col-12 md:col-4 p-3">
          <div class="text-500 font-medium mb-2">Venue</div>
          <div class="text-900">{{ hearing?.venue }}</div>
        </div>
        <div class="col-12 md:col-4 p-3">
          <div class="text-500 font-medium mb-2">Hearing Date</div>
          <div class="text-900">
            {{ hearing?.hearing_date | date : "yyyy-MM-dd" }}
          </div>
        </div>
        <div class="col-12 p-3">
          <div class="text-500 font-medium mb-2">
            Incident(Employee details & offence)
          </div>
          <div class="text-900 line-height-3">
            {{ hearing?.incidentDetails }}
          </div>
        </div>
      </div>

      <!-- incident section  -->
      <div class="col-12 pt-3 px-3">
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Hearing Details
        </div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Hearing Status</div>
        <div class="text-900 line-height-3">
          <p-badge
            [value]="hearing.hearing_status || '' | uppercase"
            [severity]="
              hearing.hearing_status === 'concluded' ? 'warning' : 'success'
            "
            class="w-24"
          ></p-badge>
        </div>
      </div>
      <div class="col-12 md:col-3 p-3">
        <div class="text-500 font-medium mb-2">Action Taken</div>
        <div class="text-900 line-height-3">
          <p-badge
            [value]="hearing.action_taken || 'N/A'"
            severity="danger"
            class="w-24"
          ></p-badge>
        </div>
      </div>

      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Hearing Progression</div>
        <div class="text-900 line-height-3">
          <p-timeline [value]="hearingActions" class="p-1">
            <ng-template pTemplate="content" let-action>
              <small class="p-text-secondary">{{
                action.action_date | date : "YYYY-MM-dd"
              }}</small>
            </ng-template>
            <ng-template pTemplate="opposite" let-action>
              {{ action.action_type }}
            </ng-template>
          </p-timeline>
        </div>
      </div>
      <div class="col-12 md:col-6 p-3">
        <div class="text-500 font-medium mb-2">Hearing Date</div>
        <div class="text-900 line-height-3">
          {{ hearing.hearing_date || "" | date : "YYYY-MM-dd" }}
        </div>
      </div>

      <!-- Evidence files -->
      <div class="col-12 flex flex-row justify-content-between mt-4">
        <div class="w-full p-3">
          <div class="text-700 font-medium text-lg mb-3">Evidence Files</div>
          <ng-container
            *ngIf="evidencesFiles.length === 0; else evidenceFilesList"
          >
            <div
              class="text-500 font-medium border-top-1 surface-border p-3 text-center"
            >
              No files attached
            </div>
          </ng-container>
          <ng-template #evidenceFilesList>
            <div
              class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
              *ngFor="let file of evidencesFiles"
            >
              <div class="flex align-items-center">
                <span class="block pi pi-file mr-2"></span>
                <div>
                  <div class="text-900 mr-2">
                    {{ file.original_filename }}
                  </div>
                  <div class="text-500 text-xs">
                    uploaded {{ file.date_uploaded | dateAsAgo }}
                  </div>
                </div>
              </div>
              <a
                href="javascript:void(0);"
                pButton
                pRipple
                icon="pi pi-download"
                class="p-button-outlined mt-2 md:mt-0"
                style="text-decoration: none"
                (click)="
                  downloadFile(
                    file.unique_file_reference,
                    file.original_filename
                  );
                  $event.stopPropagation()
                "
              ></a>
            </div>
          </ng-template>
        </div>

        <!-- Admin files -->
        <div class="col-4 p-3">
          <div class="text-700 font-medium text-lg mb-3">Admin Files</div>
          <ng-container *ngIf="adminFiles.length === 0; else adminFilesList">
            <div
              class="text-500 font-medium border-top-1 surface-border p-3 text-center"
            >
              No files attached
            </div>
          </ng-container>
          <ng-template #adminFilesList>
            <div
              class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
              *ngFor="let file of adminFiles"
            >
              <div class="flex align-items-center">
                <span class="block pi pi-file mr-2"></span>
                <div>
                  <div class="text-900 mr-2">
                    {{ file.original_filename }}
                  </div>
                  <div class="text-500 text-xs">
                    uploaded {{ file.date_uploaded | dateAsAgo }}
                  </div>
                </div>
              </div>
              <a
                href="javascript:void(0);"
                pButton
                pRipple
                icon="pi pi-download"
                class="p-button-outlined mt-2 md:mt-0"
                style="text-decoration: none"
                (click)="
                  downloadFile(
                    file.unique_file_reference,
                    file.original_filename
                  );
                  $event.stopPropagation()
                "
              ></a>
            </div>
          </ng-template>
        </div>

        <!-- Generated files -->
        <div class="col-4 p-3">
          <div class="text-700 font-medium text-lg mb-3">Generated Files</div>
          <ng-container
            *ngIf="generatedFiles.length === 0; else generatedFilesList"
          >
            <div
              class="text-500 font-medium border-top-1 surface-border p-3 text-center"
            >
              No files attached
            </div>
          </ng-container>
          <ng-template #generatedFilesList>
            <div
              class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
              *ngFor="let file of generatedFiles"
            >
              <div class="flex align-items-center">
                <span class="block pi pi-file mr-2"></span>
                <div>
                  <div class="text-900 mr-2">{{ file.original_filename }}</div>
                  <div class="text-500 text-xs">
                    uploaded {{ file.date_uploaded | dateAsAgo }}
                  </div>
                </div>
              </div>
              <a
                href="javascript:void(0);"
                pButton
                pRipple
                icon="pi pi-download"
                class="p-button-outlined mt-2 md:mt-0"
                style="text-decoration: none"
                (click)="
                  downloadFile(file.id, hearing.id); $event.stopPropagation()
                "
              ></a>
            </div>
          </ng-template>
        </div>
      </div>

      <!-- resolution Block -->
      <div class="col-12 pt-3 px-3">
        <div
          class="text-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          Verdict
        </div>
      </div>
      <div class="col-12 p-3">
        <div class="formgrid p-fluid" [formGroup]="hearingForm">
          <!----ngif for hiding-->
          <div *ngIf="hearing?.hearing_status != 'open'">
            <!-- File Upload Field -->
            <div class="field mb-4 col-12 lg:col-12">
              <label for="file_id">File</label>
              <p-fileUpload
                name="file[]"
                #fubadvanced
                [multiple]="true"
                [customUpload]="true"
                accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                [maxFileSize]="1000000"
                (uploadHandler)="onFileUpload($event, fubadvanced)"
              >
                <ng-template pTemplate="content">
                  <div *ngIf="uploadedFiles.length">
                    <div
                      class="flex p-1 flex-column"
                      *ngFor="let file of uploadedFiles"
                    >
                      <div class="flex align-items-center">
                        <span class="block pi pi-file mr-2"></span>
                        <span class="text-900"
                          >{{ file.original_filename }} ({{
                            file.file_size
                          }}
                          bytes)</span
                        >
                      </div>
                    </div>
                  </div>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>
          <!----ngif for hiding-->
          <div *ngIf="hearing?.hearing_status == 'open'">
            <div class="field">
              <label for="resolution" class="text-500 font-medium mb-2"
                >resolution</label
              >
              <p-dropdown
                formControlName="resolution"
                [options]="resolutionOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select resolution"
                class="w-full"
              ></p-dropdown>
              <div
                *ngIf="
                  hearingForm.get('resolution')?.invalid &&
                  (hearingForm.get('resolution')?.touched ||
                    hearingForm.get('resolution')?.dirty)
                "
                class="p-error mt-1"
              >
                <small><strong>resolution</strong> is required</small>
              </div>
            </div>

            <div class="field mt-3">
              <button
                pButton
                label="Conclude Hearing"
                class="p-button-success w-full"
                (click)="concludeHearing()"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
