import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { HomeComponent } from "./features/main-application/pages/home/home.component";

import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password/forgot-password.component";

import { RefreshComponent } from "./features/main-application/refresh/refresh.component";
import { AuthGuard } from "./_core/_guards/auth.guard";
import { EmployeesComponent } from "./features/main-application/pages/employees/employees.component";
import { TransgressionsComponent } from "./features/main-application/pages/transgressions/transgressions.component";
import { IncidentsComponent } from "./features/main-application/pages/incidents/incidents.component";
import { HearingComponent } from "./features/main-application/pages/hearing/hearing.component";

const routes: Routes = [
  { path: "forgot-password", component: ForgotPasswordComponent },
  {
    path: "",
    component: HomeComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "refresh",
    component: RefreshComponent,
  },

  {
    path: "employees",
    component: EmployeesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "transgressions",
    component: TransgressionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "incidents",
    component: IncidentsComponent,
    canActivate: [AuthGuard],
  },
  { path: "hearings", component: HearingComponent, canActivate: [AuthGuard] },
  { path: "login", component: LoginComponent, pathMatch: "full" },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
