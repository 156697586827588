import { formatDate } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { FileUpload } from "primeng/fileupload";
import { BehaviorSubject } from "rxjs";
import { Hearing } from "src/app/_share/_models/hearing";
import { FilesService } from "src/app/_share/_services/files.service";
import { HearingService } from "src/app/_share/_services/hearing.service";
import { IncidentService } from "src/app/_share/_services/incident.service";
import { UserService } from "src/app/_share/_services/user.service";

@Component({
  selector: "app-create-hearing",
  templateUrl: "./create-hearing.component.html",
  styleUrls: ["./create-hearing.component.scss"],
})
export class CreateHearingComponent implements OnInit {
  createHearings$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  hearingForm: FormGroup = this.formBuilder.group({
    hearing_date: ["", Validators.required],
    venue: ["", Validators.required],
    action_taken: ["hearing-scheduled", Validators.required],
    resolution: [false, Validators.required],
    incident_id: ["", Validators.required],
    hearing_status: ["open", Validators.required],
  });

  editFormData: any; // Define the type if known
  isInEditMode: boolean = false;
  fb: any;
  uploadedFiles: any[] = [];
  incidentId: number | undefined;
  incidentOptions: any[] = [];
  selectedIncident: any; // Type it appropriately if you know the structure
  hearings: Hearing[] = [];
  incidents: any[] = [];
  evidencesFiles: any[] = [];
  generatedFiles: any[] = [];
  adminFiles: any[] = [];
  resolutionOptions: any[];
  loading: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public hearingService: HearingService, // Update to correct service
    public formBuilder: FormBuilder,
    public filesService: FilesService,
    public incidentService: IncidentService,
    public userService: UserService
  ) {
    this.resolutionOptions = [
      { label: "True", value: true },
      { label: "False", value: false },
    ];
  }

  ngOnInit(): void {
    this.editFormData = this.config.data?.formData;
    this.loadHearingsAndIncidents();
    // this.loadIncidents();
    // Simulate loading completion
    setTimeout(() => {
      this.createHearings$.next(false);
    }, 1000);
    this.filterFilesByType();
    this.updateFormData();
  }
  hearing: any;

  filterFilesByType() {
    if (this.hearing && this.hearing.files) {
      this.evidencesFiles = this.hearing.files.filter(
        (file: any) => file.file_association_type === "evidence"
      );
      this.generatedFiles = this.hearing.files.filter(
        (file: any) => file.file_association_type === "generated",
        console.log("generatedFiles", this.generatedFiles)
      );
      this.adminFiles = this.hearing.files.filter(
        (file: any) => file.file_association_type === "admin"
      );
    }
  }

  loadHearingsAndIncidents() {
    this.hearingService.getHearings().subscribe((hearings: Hearing[]) => {
      this.hearings = hearings;

      // Collect IDs of incidents that already have hearings
      const hearingIncidentIds = new Set(
        hearings.map((hearing) => hearing.incident_id)
      );

      this.incidentService.getIncidents().subscribe((incidents) => {
        // Filter incidents based on mode
        this.incidents = incidents
          .filter(
            (incident) =>
              incident.action_taken === "Notice of Hearing" &&
              (this.isInEditMode || !hearingIncidentIds.has(incident.id)) // Exclude if in create mode
          )
          .map((incident) => ({
            label: `${incident.employee.employee_code} ${incident.employee.first_name} ${incident.employee.last_name} - ${incident.description} - ${incident.transgression.offence}`,
            value: incident.id,
          }));

        // Only patch the form after the incidents are loaded
        this.updateFormData();
      });
    });
  }

  getFileLinks(files: any[]): any[] {
    if (!files || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      unique_file_reference: file.unique_file_reference,
      original_filename: file.original_filename,
    }));
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.filesService
      .downloadFile(unique_file_reference, original_filename)
      .subscribe(
        () => {
          console.log("File downloaded successfully");
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }

  //bug might be backend related
  onFileUpload(event$: any, uploader: FileUpload) {
    for (let file of event$.files) {
      let formData = new FormData();
      formData.append("file", file);
      if (!this.isInEditMode) {
        this.filesService.uploadHearingFile(formData, 0).subscribe({
          next: (response: any) => {
            console.log("File uploaded successfully", response);
            // Store uploaded file data for later use
            const uploadedFileData = response.body.data; // Capture the response data
            this.uploadedFiles.push(uploadedFileData);
          },
          error: (err: any) => {
            console.error("Error uploading file", err);
          },
        });
      } else {
        this.filesService
          .uploadHearingFile(formData, this.hearing.id)
          .subscribe({
            next: (response: any) => {
              console.log("File uploaded successfully", response);
              // Store uploaded file data for later use
              const uploadedFileData = response.body.data; // Capture the response data
              this.uploadedFiles.push(uploadedFileData);
            },
            error: (err: any) => {
              console.error("Error uploading file", err);
            },
          });
      }
    }
    // Clear the file list in the uploader
    uploader.clear();
  }

  updateFormData() {
    console.log("editFormData", this.editFormData);
    if (this.editFormData) {
      //settimeout fixes the weird error with 2 different time errors
      // setTimeout(() => {
      let new_date = new Date(this.editFormData.hearing_date);
      this.hearingForm.patchValue({
        hearing_date: new_date,
        venue: this.editFormData.venue,
        action_taken: this.editFormData.action_taken,
        resolution: this.editFormData.resolution,
        incident_id: this.editFormData.incident_id,
        hearing_status: this.editFormData.hearing_status,
      });

      this.isInEditMode = true;
      this.hearing = this.editFormData; // Store the hearing data
      this.filterFilesByType();
      // }
      // );
    } else {
      this.isInEditMode = false;
    }
  }

  downloadGeneratedHearingNotice(hearingId: number) {
    this.filesService.downloadGeneratedHearingNotice(hearingId).subscribe(
      (response: any) => {
        console.log("response received:", response);

        let defaultFileName = `WrittenWarning_${hearingId}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  downloadGeneratedNotice(file_id: number, hearing_id: number) {
    console.log("Downloading generated notice for hearing:", hearing_id);
    console.log("File ID:", file_id);
    this.filesService.downloadGeneratedNotice(file_id, hearing_id).subscribe(
      (response: any) => {
        console.log("Response received:", response);

        let defaultFileName = `HearingDocument_${hearing_id}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1]
              .replace(/['"]/g, "")
              .replace("utf-8", "")
              .replace(/%20/g, "_")
              .trim(); // Replace %20 with underscores
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }

        // Create a blob and trigger the download
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Use the sanitized filename
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  onAddHearingFormSubmit() {
    this.loading = true;
    if (this.hearingForm.invalid) {
      this.hearingForm.markAllAsTouched();
      return;
    }

    if (this.isInEditMode) {
      this.hearingForm.patchValue({
        hearing_date: formatDate(
          this.hearingForm.value.hearing_date,
          "yyyy-MM-dd HH:mm:ss",
          "en-US"
        ),
      });
    }

    const hearingData = {
      ...this.hearingForm.value,
      files: this.uploadedFiles,
    };

    console.log("submitted", hearingData);

    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      this.hearingService
        .updateHearing(this.editFormData.id, hearingData)
        .subscribe({
          next: (res: any) => {
            console.log("Hearing updated successfully", res);
            this.ref.close(this.hearingForm.value);
          },
          error: (err: any) => {
            console.error("Error updating hearing", err);
          },
        });
    } else {
      this.hearingService.addHearings(hearingData).subscribe({
        next: (res: any) => {
          console.log("Hearing added successfully", res);
          this.hearingService.refreshHearings();

          this.userService.sendSms(this.selectedIncident).subscribe({
            next: (res: any) => {
              console.log("sms sent successfully", res);
            },
            error: (err: any) => {
              console.error("Error sending sms", err);
            },
          });
          this.ref.close(this.hearingForm.value);
        },
        error: (err: any) => {
          console.error("Error adding hearing", err);
        },
      });
      // if (this.hearingForm.invalid) {
      //   this.hearingForm.markAllAsTouched();
      //   return;
      // }
      // if (this.isInEditMode && this.editFormData?.id !== undefined) {
      //   this.hearingService
      //     .updateHearing(this.editFormData.id, this.hearingForm.value)
      //     .subscribe({
      //       next: (res: any) => {
      //         console.log("Hearing updated successfully", res);
      //         this.ref.close(this.hearingForm.value);
      //       },
      //       error: (err: any) => {
      //         console.error("Error updating hearing", err);
      //       },
      //     });
      // } else {
      //   this.hearingService.addHearings(this.hearingForm.value).subscribe({
      //     next: (res: any) => {
      //       console.log("Hearing added successfully", res);
      //       this.hearingService.refreshHearings(); // Update this if necessary
      //       this.ref.close(this.hearingForm.value);
      //     },
      //     error: (err: any) => {
      //       console.error("Error adding hearing", err);
      //     },
      //   });
      // }
    }
  }
}
