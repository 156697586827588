<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>
<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-users text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Employees</span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center">
          <div class="flex flex-1 justify-content-end align-items-center">
            <!-- Global Search Input Field -->
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                placeholder="Search employees..."
                (input)="onGlobalSearch($event)"
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-4">
      <div class="surface-card shadow-2 flex p-3 flex-column lg:flex-row">
        <button
          pButton
          class="bg-blue-400"
          icon="pi pi-plus"
          label="Add Employee"
          (click)="showAdd()"
        ></button>
      </div>
    </div>
    <div class="col-12">
      <ng-template #loadedContent>
        <div class="surface-card shadow-2 flex p-3 flex-column">
          <p-table
            *ngIf="employees && employees.length > 0; else noDataTemplate"
            [value]="employees"
            [tableStyle]="{ 'min-width': '100%' }"
            [rowHover]="true"
            [responsiveLayout]="'scroll'"
            scrollHeight="75vh"
          >
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of columns">
                  <span class="font-semibold text-md text-color-secondary">{{
                    col.header
                  }}</span>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-employee>
              <tr style="cursor: pointer" (click)="showViewEmployee(employee)">
                <td *ngFor="let col of columns">
                  <div class="flex align-items-center gap-3">
                    <span *ngIf="col.field === 'status'">
                      <p-tag [severity]="getEmployeeStatusSeverity(employee[col.field])">
                        {{ employee[col.field] | uppercase }}
                      </p-tag>
                    </span>
                    <div>
                      <p
                        class="mt- mb-2 font-medium text-md text-color-primary"
                      >
                      <span *ngIf="col.field !== 'status'">
                        {{ employee[col.field] }}
                      </span>
                       
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-pencil"
                    class="p-button-text p-button-success"
                    (click)="editEmployee(employee); $event.stopPropagation()"
                  ></button>

                  <button
                    type="button"
                    pButton
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger"
                    (click)="deleteEmployee(employee); $event.stopPropagation()"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #noDataTemplate>
    <div class=" flex p-3 flex-column align-items-center justify-content-center">
      <p>No data available at present</p>
    </div>
  </ng-template>
  <div *ngIf="this.Employees$ | async; else loadedContent">
    <div class="surface-card shadow-2 flex p-3 flex-column">
      <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>
