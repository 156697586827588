import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { Leave } from "src/app/_share/_models/leave";
import { CreateLeaveComponent } from "../create-leave/create-leave.component";
import { ViewLeaveComponent } from "../view-leave/view-leave.component";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { LeaveBalanceService } from "src/app/_share/_services/leaveBalance.service";
import { LeaveBalance } from "src/app/_share/_models/leaveBalance";
import { ViewLeaveBalanceComponent } from "../view-leave-balance/view-leave-balance.component";
import { CreateLeaveBalanceComponent } from "../create-leave-balance/create-leave-balance.component";
import { Routes } from "@angular/router";
import { LeavesComponent } from "../leaves/leaves.component";
import { reportsService } from "src/app/_share/_services/reports.service";

@Component({
  selector: "app-leave-balances",
  templateUrl: "./leave-balances.component.html",
  styleUrls: ["./leave-balances.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class LeaveBalancesComponent implements OnInit, OnDestroy {
  constructor(
    public dialogService: DialogService,
    public leaveService: LeaveService,
    public leaveBalanceService: LeaveBalanceService,
    public employeeService: EmployeeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private reportsService: reportsService
  ) {}

  routes: Routes = [
    {
      path: "leaves",
      component: LeavesComponent, // Component for managing leaves
    },
    {
      path: "leaveBalances",
      component: LeaveBalancesComponent, // Component for leave balances
    },
    { path: "", redirectTo: "/leaveBalances", pathMatch: "full" }, // Default route
  ];

  employees: any[] = [];
  leavesBalances: any[] = [];
  LeaveBalances$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  leavesBalancesObservable$: any;
  private subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.LeaveBalances$.next(false);
      this.loadEmployees();
    }, 3000);
    this.subscriptions.add(
      this.employeeService.getEmployees().subscribe((employees) => {
        this.employees = employees;
        this.loadLeaveBalances();
      })
    );
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadLeaveBalances() {
    this.subscriptions.add(
      this.leaveBalanceService.getLeaveBalances().subscribe((leaveBalances) => {
        this.leavesBalances =
          this.sortLeaveBalancesByEmployeeName(leaveBalances);
      })
    );
  }

  sortLeaveBalancesByEmployeeName(
    leaveBalances: LeaveBalance[]
  ): LeaveBalance[] {
    // Map employee_id to last name
    const employeeIdToLastNameMap = this.employees.reduce((acc, employee) => {
      acc[employee.id] = employee.last_name;
      return acc;
    }, {});

    // Sort the leave balances by last name
    return leaveBalances.sort((a, b) => {
      const aLastName = employeeIdToLastNameMap[a.employee_id] ?? "";
      const bLastName = employeeIdToLastNameMap[b.employee_id] ?? "";
      return aLastName.localeCompare(bLastName);
    });
  }

  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  exportLeaveBalances() {
    this.reportsService.generate_leave_balance_report().subscribe({
      next: (response: Blob) => {
        // Create a link element to trigger the download
        const link = document.createElement("a");
        const fileName = `leave_balance_report_${new Date().toLocaleDateString()}.xlsx`;
        link.href = window.URL.createObjectURL(response); // Create a URL for the Blob object
        link.download = fileName; // Set the filename for the downloaded file
        link.click(); // Simulate a click to trigger the download
        window.URL.revokeObjectURL(link.href); // Cleanup the object URL
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Leave balance report has been generated and downloaded.",
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: "error",
          summary: "Error",
          detail: "An error occurred while generating the report.",
        });
        console.error("Error downloading report:", err);
      },
    });
  }

  getLeavesOptionsList() {
    this.subscriptions.add(
      this.leaveBalanceService
        .getLeaveBalances()
        .subscribe((leavesBalances: any[]) => {
          this.leavesBalances = leavesBalances;
        })
    );
  }

  columns = [
    { field: "employee_id", header: "Employee Details" },
    { field: "annual_leave_balance", header: "Annual Leave Balance" },
    { field: "sick_leave_balance", header: "Sick Leave Balance" },
    { field: "maternity_leave_balance", header: "Maternity Leave Balance" },
    { field: "unpaid_leave_balance", header: "Unpaid Leave Balance" },
    {
      field: "family_responsiblity_leave_amount",
      header: "family responsiblity leave Balance",
    },
    { field: "study_leave_balance", header: "Study Leave balance" },
  ];

  onGlobalSearch(event: any) {
    const searchValue = event.target.value;
    if (searchValue.trim()) {
      // Filter the  array based on the global search query
      this.leavesBalances = this.filterLeaves(searchValue);
    } else {
      // If search is cleared, load the full list
      this.getLeavesOptionsList();
    }
  }
  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }
  getEmployeeNameById(id: number): string {
    const employee = this.employees.find((emp: any) => emp.id === id);
    return employee
      ? `${employee.employee_code}  ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  getLeaveStatusSeverity(value: string): string {
    switch (value.toLowerCase()) {
      case "suspended":
      case "unpaid suspension":
        return "warning";
      case "active":
        return "success";
      case "terminated":
        return "danger";
      default:
        return "info";
    }
  }
  filterLeaves(query: string): any[] {
    return this.leavesBalances.filter((leavesBalance) =>
      this.columns.some((column) => {
        if (column.field === "employee_id") {
          const employeeName = this.getEmployeeNameById(
            leavesBalance[column.field]
          );
          return employeeName.toLowerCase().includes(query.toLowerCase());
        }
        // Otherwise, filter based on values of leavesBalance fields
        return String(leavesBalance[column.field])
          .toLowerCase()
          .includes(query.toLowerCase());
      })
    );
  }

  showViewLeave(rowData: any) {
    this.ref = this.dialogService.open(ViewLeaveBalanceComponent, {
      data: rowData,

      header: "Leave balance Details",
      width: "90%",
      height: "70%",
      closeOnEscape: true,
    });
  }

  showAdd() {
    this.ref = this.dialogService.open(CreateLeaveBalanceComponent, {
      data: {
        leaveData: null,
        listofleaves: this.leaveService.getLeaves(),
      },
      header: "Add Leave balance",
      width: "65%",
      height: "55%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.loadLeaveBalances();
        // this.getLeavesOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Leave balance successfully added",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Adding leave balance was cancelled",
        });
      }
    });
  }

  // Edit function
  editLeave(rowData: any) {
    console.log("row", rowData);
    this.ref = this.dialogService.open(CreateLeaveBalanceComponent, {
      data: {
        formdata: rowData,
      },
      header: "Edit Leave Balance",
      width: "70%",
      height: "55%",
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.loadLeaveBalances();
        // this.getLeavesOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Leave balance updated successfully",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Editing leave balance was cancelled",
        });
      }
    });
  }
  // deleteLeave(rowData: any) {
  //   this.confirmationService.confirm({
  //     message: "Are you sure you want to delete this leave?",
  //     header: "Delete Confirmation",
  //     icon: "pi pi-info-circle",
  //     accept: () => {
  //       this.leaveService.deleteLeave(rowData.id).subscribe(() => {
  //         this.getLeavesOptionsList();
  //         this.messageService.add({
  //           severity: "success",
  //           summary: "Success",
  //           detail: "Leave deleted successfully",
  //         });
  //       });
  //     },
  //     reject: () => {
  //       this.messageService.add({
  //         severity: "info",
  //         summary: "Rejected",
  //         detail: "Leave was not deleted",
  //       });
  //     },
  //   });
  // }
}
