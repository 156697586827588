import { Injectable, Input } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { Transgression } from "../_models/transgression";
import { ApiService } from "src/app/_core/_services/api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class TransgressionService {
  //not have in service and only return in component but I use for refresh
  private transgressions: Transgression[] = [];
  private transgressions$ = new BehaviorSubject<Transgression[]>([]);

  constructor(public api: ApiService, private http: HttpClient) {}
  //this is fastcrud
  // getTransgressions(): Observable<Transgression[]> {
  //   return this.api.getAPI<Transgression[]>("transgressions/get").pipe(
  //     map((res) =>
  //       res.body.data.sort(
  //         (a: { id: number }, b: { id: number }) => a.id - b.id
  //       )
  //     ),
  //     tap((transgressions) => this.transgressions$.next(transgressions))
  //   );
  // }

  getTransgressions(): Observable<Transgression[]> {
    return this.api.getAPI<Transgression[]>("transgressions").pipe(
      map((res) =>
        res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
      ),
      tap((transgressions) => this.transgressions$.next(transgressions))
    );
  }

  addTransgression(newTransgression: Transgression): Observable<any> {
    return this.api
      .postAPI<Transgression>("transgressions/create", newTransgression)
      .pipe(
        tap((res: any) => {
          console.log("transgression added", res);
        }),
        switchMap(() => this.getTransgressions())
      );
  }

  updateTransgression(
    transgressionId: number,
    transgressionsData: Transgression
  ): Observable<any> {
    return this.api
      .patchAPI<Transgression>(
        "transgressions/update",
        transgressionId,
        transgressionsData
      )
      .pipe(
        tap((res: any) => {
          console.log("transgression updated", res);
        }),
        switchMap(() => this.getTransgressions())
      );
  }

  deleteTransgression(transgressionId: number): Observable<any> {
    return this.api
      .deleteAPI<any>("transgressions/delete", transgressionId)
      .pipe(tap(() => this.refreshTransgressions()));
  }
  refreshTransgressions(): Observable<Transgression[]> {
    return this.getTransgressions().pipe(
      tap((transgressions) => {
        this.transgressions = transgressions;
        this.transgressions$.next(this.transgressions);
      })
    );
  }
  get transgressionsObservable$(): Observable<Transgression[]> {
    return this.transgressions$.asObservable();
  }
}
