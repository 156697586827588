<div *ngIf="leaveBalance">
  <!-- Loaded content -->
  <div class="border-round">
    <div class="grid grid-nogutter">
      <div class="col-12 pt-3 px-3">
        <div
          class="tex-700 font-medium mb-2 text-xl surface-border border-bottom-1"
        >
          leaveBalance Details
        </div>
      </div>
      <div class="col-12 md:col-12 p-3">
        <div class="text-500 font-medium mb-2">Employee Details</div>
        <div class="text-900">
          {{ getEmployeeNameById(leaveBalance.employee_id) }}
        </div>
      </div>

      <div class="col-12 md:col-4 p-3 block">
        <div class="text-500 font-medium mb-2">Annual Leave Balance</div>
        <div class="text-900">{{ leaveBalance.annual_leave_balance }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Sick Leave Balance</div>
        <div class="text-900">{{ leaveBalance.sick_leave_balance }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Maternity Leave Balance</div>
        <div class="text-900">{{ leaveBalance.maternity_leave_balance }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Unpaid Leave Balance</div>
        <div class="text-900">{{ leaveBalance.unpaid_leave_balance }}</div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">
          Family Responsiblity Leave Balance
        </div>
        <div class="text-900">
          {{ leaveBalance.family_responsiblity_leave_amount }}
        </div>
      </div>
      <div class="col-12 md:col-4 p-3">
        <div class="text-500 font-medium mb-2">Study Leave Balance</div>
        <div class="text-900">{{ leaveBalance.study_leave_balance }}</div>
      </div>

      <!-- {{ leave | json }} -->
      <div class="col-12 p-3">
        <div *ngIf="loadingHistory; else historyLoaded">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <ng-template #historyLoaded>
          <div class="text-500 font-medium mb-2">Employee Incident History</div>
          <div class="text-900 line-height-3">
            <ng-container
              *ngIf="
                (employeeLeavehistory$ | async)?.length;
                else noDataTemplate
              "
            >
              <p-timeline
                [value]="(employeeLeavehistory$ | async) || []"
                align="alternate"
                class="p-1"
              >
                <ng-template pTemplate="opposite" let-historyItem>
                  <div
                    [style]="{ width: '100%' }"
                    class="surface-card border-1 border-300 p-3"
                  >
                    <div class="text-900 font-semibold mb-2">
                      {{ historyItem.leave_status | uppercase }}
                    </div>
                    <div class="text-900 line-height-1">
                      <p-tag
                        [severity]="
                          getLeaveStatusSeverity(historyItem.leave_action_type)
                        "
                        >{{ historyItem.leave_action_type | uppercase }}</p-tag
                      >
                    </div>

                    <small class="p-text-secondary">{{
                      historyItem.start_period | date : "YYYY-MM-dd"
                    }}</small>
                    -
                    <small class="p-text-secondary">{{
                      historyItem.end_period | date : "YYYY-MM-dd"
                    }}</small>

                    <!-- <div class="text-500 font-medium my-1">
                        Incident Details:
                      </div>
                      <div class="text-900 line-height-3">
                        {{ historyItem.description }}
                      </div> -->
                  </div>
                </ng-template>
              </p-timeline>
            </ng-container>
            <ng-template #noDataTemplate>
              <div
                class="flex p-3 flex-column align-items-center justify-content-center"
              >
                <p>No history available at present</p>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
