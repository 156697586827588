import { Injectable, Input } from "@angular/core";
import { Observable, Subject, map } from "rxjs";
import { HearingAction } from "../_models/hearingAction";
import { ApiService } from "src/app/_core/_services/api.service";

@Injectable({
  providedIn: "root",
})
export class HearingActionService {
  @Input() hearingAction: any;
  @Input() hearingActionObservable$: Subject<any> = new Subject<any>();

  hearingactionQuery$: any;

  constructor(public api: ApiService) {}

  get_hearing_actions(): Observable<HearingAction[]> {
    return this.api
      .getAPI<HearingAction[]>("hearing-actions")
      .pipe(map((res) => res.body));
  }

  get_hearing_actions_by_hearing(hearingId: number): Observable<any[]> {
    return this.api
      .getAPI<HearingAction[]>(`hearing-actions/hearing/${hearingId}`)
      .pipe(map((res) => res.body.data));
  }

  postHearingAction(
    hearingActionData: HearingAction
  ): Observable<HearingAction> {
    return this.api
      .postAPI<HearingAction>("hearing-actions", hearingActionData)
      .pipe(map((res) => res.body));
  }
}
