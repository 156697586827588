<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>

<div
  class="surface-overlay shadow-2 flex relative lg:static justify-content-between"
  style="min-height: 42px"
>
  <!-- Hamburger Menu for Mobile View -->
  <a
    pRipple
    class="cursor-pointer inline-flex align-items-center px-3 lg:hidden text-700"
    pStyleClass="@next"
    enterClass="hidden"
    leaveToClass="hidden"
    [hideOnOutsideClick]="true"
  >
    <i class="pi pi-bars text-2xl"></i>
  </a>

  <!-- Horizontal Menu for Large Screens -->
  <div
    class="hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
  >
    <ul class="flex list-none p-0 m-0 flex-column lg:flex-row">
      <!-- Leave Balance -->
      <li class="h-full">
        <a
          pRipple
          class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-blue-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3"
          [routerLink]="['/policies']"
          routerLinkActive="active"
          aria-label="Policies"
        >
          <span class="pi pi-wallet mr-2"></span>
          <span class="font-medium">Leave Calendar</span>
        </a>
      </li>
      <!-- Leave Management -->
      <li class="h-full">
        <a
          pRipple
          class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-blue-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3"
          [routerLink]="['/leaves']"
          routerLinkActive="active"
          aria-label="Leave Management"
        >
          <span class="pi pi-calendar mr-2"></span>
          <span class="font-medium">Leave Management</span>
        </a>
      </li>
      <!-- Leave Balances -->
      <li class="h-full">
        <a
          pRipple
          class="cursor-pointer h-full inline-flex align-items-center text-600 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent hover:border-blue-500 transition-colors transition-duration-150 py-3 lg:py-0 px-3"
          [routerLink]="['/leaveBalances']"
          routerLinkActive="active"
          aria-label="Leave Balances"
        >
          <span class="pi pi-calendar mr-2"></span>
          <span class="font-medium">Leave Balance</span>
        </a>
      </li>
    </ul>
  </div>
</div>

<!-- Spacing between navbar and content -->
<div style="margin-top: 10px"></div>

<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-fw pi-calendar text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Leave Calendar</span>
          <!-- Info Icon with Tooltip -->
          <span
            pTooltip="This section allows you view upcoming approved leave entries."
            tooltipPosition="top"
            class="pi pi-info-circle text-xl cursor-pointer"
            style="margin-left: 10px"
          ></span>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column lg:flex-row gap-2">
        <div class="col-12 lg:col-4">
          <button
            pButton
            class="bg-gray-400 w-full"
            label="Leave Payroll"
          ></button>
        </div>
        <div class="col-12 lg:col-4">
          <button
            pButton
            class="bg-gray-400 w-full"
            label="Future Feature"
          ></button>
        </div>
        <!--  icon="pi pi-plus" -->
        <div class="col-12 lg:col-4">
          <button
            pButton
            class="bg-gray-400 w-full"
            label="Future Feature"
          ></button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <ng-template #loadedContent>
        <!-- Leave Calendar with Scheduled Leaves -->
        <full-calendar [options]="calendarOptions"> </full-calendar>
      </ng-template>
    </div>
  </div>
  <!-- <ng-template #noDataTemplate>
    <div class="flex p-3 flex-column align-items-center justify-content-center">
      <p>No data available at present</p>
    </div>
  </ng-template> -->
  <div *ngIf="this.policies$ | async; else loadedContent">
    <div class="surface-card shadow-2 flex p-3 flex-column">
      <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>
