import { Component, OnDestroy, OnInit } from "@angular/core";

import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { Leave } from "src/app/_share/_models/leave";
import { CreateLeaveComponent } from "../create-leave/create-leave.component";
import { ViewLeaveComponent } from "../view-leave/view-leave.component";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { Routes } from "@angular/router";
import { LeaveBalancesComponent } from "../leave-balances/leave-balances.component";
import { FilesService } from "src/app/_share/_services/files.service";

@Component({
  selector: "app-leaves",
  templateUrl: "./leaves.component.html",
  styleUrls: ["./leaves.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class LeavesComponent implements OnInit, OnDestroy {
  constructor(
    public dialogService: DialogService,
    public leaveService: LeaveService,
    public employeeService: EmployeeService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public FilesService: FilesService
  ) {}

  routes: Routes = [
    {
      path: "leaves",
      component: LeavesComponent, // Component for managing leaves
    },
    {
      path: "leaveBalances",
      component: LeaveBalancesComponent, // Component for leave balances
    },
    { path: "", redirectTo: "/leaveBalances", pathMatch: "full" }, // Default route
  ];

  employees: any[] = [];
  Leaves$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  ref: DynamicDialogRef | undefined;
  leaves: any[] = [];
  leavesObservable$: any;
  private subscriptions: Subscription = new Subscription();

  ngOnInit(): void {
    setTimeout(() => {
      this.Leaves$.next(false);
      this.loadEmployees();
    }, 3000);
    this.subscriptions.add(
      this.leaveService.getLeaves().subscribe((leaves) => {
        this.leaves = leaves;
      })
    );
    this.refreshLeaveData();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selectedRowCMObservable$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  getLeavesOptionsList() {
    this.subscriptions.add(
      this.leaveService.getLeaves().subscribe((leaves: any[]) => {
        this.leaves = leaves;
      })
    );
  }

  columns = [
    { field: "leave_status", header: "Status" },
    { field: "employee_id", header: "Employee Details" },
    { field: "leave_action_type", header: "Leave Type" },
    { field: "sick_leave_amount", header: "Sick Leave" },
    { field: "annual_leave_amount", header: "Annual Leave" },
    { field: "maternity_leave_amount", header: "Maternity Leave" },
    {
      field: "family_responsiblity_leave_amount",
      header: "Family Responsibility Leave",
    },
    { field: "unpaid_leave_amount", header: "Unpaid Leave" },
    { field: "study_leave_amount", header: "Study Leave" },
    { field: "start_period", header: "Start Date" },
    { field: "end_period", header: "End Date" },
  ];

  onGlobalSearch(event: any) {
    const searchValue = event.target.value;
    if (searchValue.trim()) {
      // Filter the  array based on the global search query
      this.leaves = this.filterLeaves(searchValue);
    } else {
      // If search is cleared, load the full list
      this.getLeavesOptionsList();
    }
  }
  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }
  getEmployeeNameById(id: number): string {
    const employee = this.employees.find((emp: any) => emp.id === id);
    return employee
      ? `${employee.employee_code}  ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  getLeaveStatusSeverity(value: string): string {
    switch (value.toLowerCase()) {
      case "suspended":
      case "cancelled":
        return "warning";
      case "approved":
        return "success";
      case "denied":
        return "danger";
      default:
        return "info";
    }
  }
  filterLeaves(query: string): any[] {
    return this.leaves.filter((leaves) =>
      this.columns.some((column) => {
        if (column.field === "employee_id") {
          const employeeName = this.getEmployeeNameById(leaves[column.field]);
          return employeeName.toLowerCase().includes(query.toLowerCase());
        }
        // Otherwise, filter based on values of leavesBalance fields
        return String(leaves[column.field])
          .toLowerCase()
          .includes(query.toLowerCase());
      })
    );
  }

  // Refresh the files list
  refreshFiles() {
    this.subscriptions.add(
      this.FilesService.refreshFiles().subscribe((files) => {
        console.log("Files refreshed", files);
      })
    );
  }

  private refreshLeaveData() {
    this.getLeavesOptionsList();
    this.refreshFiles();
  }

  showViewLeave(rowDatas: any) {
    this.refreshLeaveData();
    this.ref = this.dialogService.open(ViewLeaveComponent, {
      data: {
        leave: rowDatas,
      },
      header: "Leave Details",
      width: "90%",
      height: "70%",
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getLeavesOptionsList();
        this.refreshFiles();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Leave successfully concluded",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: " leave action was cancelled",
        });
      }
    });
  }
  showAdd() {
    const leaveOptions = this.leaves.map((leaves) => ({
      id: leaves.id,
    }));

    this.ref = this.dialogService.open(CreateLeaveComponent, {
      data: {
        leaveOptions: leaveOptions,
      },
      header: "Schedule Leave",
      width: "70%",
      height: "65%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.getLeavesOptionsList();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Leave action successfully added",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Adding leave action was cancelled",
        });
      }
    });
  }

  editLeave(rowData: any) {
    this.refreshLeaveData();
    this.ref = this.dialogService.open(CreateLeaveComponent, {
      data: {
        formData: rowData,
      },
      header: "Update Leave",
      width: "75%",
      height: "75%",
      closeOnEscape: true,
    });
    this.ref.onClose.subscribe((result) => {
      if (result) {
        console.log("rowdata", rowData);
        const leaveId = rowData.id;

        // Call the update service to update the leave
        this.leaveService.updateleave(leaveId, result).subscribe(() => {
          // Refresh the leave list
          this.getLeavesOptionsList();

          // Display success message
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Leave action updated successfully",
          });
          // this.loadHearingsAndIncidents();
        });
      } else {
        // Toast message for closing the dialog without saving
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Editing leave action was cancelled",
        });
        // this.loadHearingsAndIncidents();
      }
    });
  }

  cancelLeave(rowData: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to cancel this leave?",
      header: "Cancel Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.leaveService.cancelLeave(rowData.id).subscribe(() => {
          this.getLeavesOptionsList();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail:
              "Leave action canceled successfully, leave has been refunded",
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Leave cancellation was aborted",
        });
      },
    });
  }

  deleteLeave(rowData: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this leave?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.leaveService.deleteLeave(rowData.id).subscribe(() => {
          this.getLeavesOptionsList();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Leave action deleted successfully",
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: "info",
          summary: "Rejected",
          detail: "Leave was not deleted",
        });
      },
    });
  }
}
