import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ApiService } from "src/app/_core/_services/api.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class reportsService {
  private apiUrl = environment.apiUrl;

  constructor(private api: HttpClient) {}

  generate_leave_balance_report(): Observable<Blob> {
    return this.api.get(
      `${this.apiUrl}/reports/generate_leave_balance_report`,
      {
        responseType: "blob",
      }
    );
  }
}
