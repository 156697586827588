<div
  *ngIf="this.createLeaveBalances$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
</div>

<ng-template #loadedContent>
  <form [formGroup]="leaveForm" (ngSubmit)="onAddleaveFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <!-- employees Field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="employee_id">Employee Details</label>
          <p-dropdown
            [options]="employees"
            optionLabel="label"
            optionValue="value"
            formControlName="employee_id"
            placeholder="Select an employee"
            [filter]="true"
            filterPlaceholder="Search employees by code or name"
              [disabled]="isInEditMode"
          ></p-dropdown>
          <div
            *ngIf="
              leaveForm.controls['employee_id'].invalid &&
              (leaveForm.controls['employee_id'].dirty ||
                leaveForm.controls['employee_id'].touched)
            "
            class="p-error"
          >
            <small class="p-error block"
              ><strong> Employee</strong> is required</small
            >
          </div>
        </div>
        <!-- annual_leave_balance -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="annual_leave_balance">Annual Leave Balance</label>
          <input
            formControlName="annual_leave_balance"
            type="text"
            class="form-control"
            id="annual_leave_balance"
            placeholder="please enter existing annual leave balance"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('annual_leave_balance')?.invalid &&
              (leaveForm.get('annual_leave_balance')?.touched ||
                leaveForm.get('annual_leave_balance')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>annual leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- sick leave amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="sick_leave_balance">Sick Leave Balance</label>
          <input
            formControlName="sick_leave_balance"
            type="text"
            class="form-control"
            id="sick_leave_balance"
            placeholder="please enter existing sick leave balance"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('sick_leave_balance')?.invalid &&
              (leaveForm.get('sick_leave_balance')?.touched ||
                leaveForm.get('sick_leave_balance')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>sick leave Balance</strong> is required</small
            >
          </div>
        </div>

        <!-- maternity_leave_balance -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="maternity_leave_balance">Maternity Leave Balance</label>
          <input
            formControlName="maternity_leave_balance"
            type="text"
            class="form-control"
            id="maternity_leave_balance"
            placeholder="please enter existing maternity leave balance"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('maternity_leave_balance')?.invalid &&
              (leaveForm.get('maternity_leave_balance')?.touched ||
                leaveForm.get('maternity_leave_balance')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>maternity leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- unpaid_leave_balance -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="unpaid_leave_balance">Unpaid Leave Balance</label>
          <input
            formControlName="unpaid_leave_balance"
            type="text"
            class="form-control"
            id="unpaid_leave_balance"
            placeholder="please enter unpaid annual leave balance"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('unpaid_leave_balance')?.invalid &&
              (leaveForm.get('unpaid_leave_balance')?.touched ||
                leaveForm.get('unpaid_leave_balance')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>maternity leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- family_responsiblity_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="family_responsiblity_leave_amount"
            >Family Responsiblity Leave Balance</label
          >
          <input
            formControlName="family_responsiblity_leave_amount"
            type="text"
            class="form-control"
            id="family_responsiblity_leave_amount"
            placeholder="please enter family responsibility leave balance"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('family_responsiblity_leave_amount')?.invalid &&
              (leaveForm.get('family_responsiblity_leave_amount')?.touched ||
                leaveForm.get('family_responsiblity_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>family resonsiblity leave balance</strong> is required</small
            >
          </div>
        </div>
        <!-- study_leave_balance -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="study_leave_balance"
            >Study Leave Balance</label
          >
          <input
            formControlName="study_leave_balance"
            type="text"
            class="form-control"
            id="study_leave_balance"
            placeholder="please enter study leave balance"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('study_leave_balance')?.invalid &&
              (leaveForm.get('study_leave_balance')?.touched ||
                leaveForm.get('study_leave_balance')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>study leave balance</strong> is required</small
            >
          </div>
        </div>
        <div class="field col-12">
          <p-button
            styleClass="bg-blue-400"
            type="submit"
            label="Submit"
            class="w-6"
          ></p-button>
          <!-- [loading]="loading" -->
        </div>
      </div>
    </div>
  </form>
</ng-template>
