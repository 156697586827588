<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>
<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-ban text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Suspensions</span>
                    <!-- Info Icon with Tooltip -->
                    <span pTooltip="This section allows you suspend employees for a specific period of time. You can also view, edit , they will autmatically be reinstated after the suspension period has ended. You can also download the suspension notice for each employee."
                    tooltipPosition="top"
                    class="pi pi-info-circle text-xl cursor-pointer"
                    style="margin-left: 10px;"></span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center">
          <div class="flex flex-1 justify-content-end align-items-center">
            <!-- Global Search Input Field -->
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                placeholder="Search suspensions..."
                (input)="onGlobalSearch($event)"
                class="p-inputtext-sm"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-4">
      <div class="surface-card shadow-2 flex p-3 flex-column lg:flex-row">
        <button
          pButton
          class="bg-blue-400"
          icon="pi pi-plus"
          label="Add Suspension"
          (click)="showAdd()"
        ></button>
      </div>
    </div>
    <div class="col-12">
      <ng-template #loadedContent>
        <div class="surface-card shadow-2 flex p-3 flex-column">
          <p-table
            *ngIf="suspensions && suspensions.length > 0; else noDataTemplate"
            [value]="suspensions"
            [tableStyle]="{ 'min-width': '100%' }"
            [rowHover]="true"
            [responsiveLayout]="'scroll'"
            scrollHeight="75vh"
          >
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of columns">
                  <span class="font-semibold text-md text-color-secondary">{{
                    col.header
                  }}</span>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-suspension>
              <tr
                style="cursor: pointer"
                (click)="showViewSuspension(suspension)"
              >
                <td *ngFor="let col of columns">
                  <div class="flex align-items-center gap-3">
                    <!-- Check if the field is 'suspension_status' and display tag with severity -->
                    <span *ngIf="col.field === 'suspension_status'">
                      <p-tag
                        [severity]="
                          getSuspensionStatusSeverity(suspension[col.field])
                        "
                      >
                        {{ suspension[col.field] | uppercase }}
                      </p-tag>
                    </span>
                    <!-- Check if the field is 'employee_id' and display the employee name -->
                    <span *ngIf="col.field === 'employee_id'">
                      {{ getEmployeeNameById(suspension[col.field]) }}
                    </span>

                    <!-- For date fields, format them to a readable format -->
                    <span *ngIf="col.field === 'start_date'">
                      {{ suspension[col.field] | date : "dd MMM yyyy" }}
                    </span>

                    <span *ngIf="col.field === 'end_date'">
                      {{ suspension[col.field] | date : "dd MMM yyyy" }}
                    </span>
                    
                    <span *ngIf="col.field === 'reason'">
                      {{ suspension[col.field] }}
                    </span>

                    <!-- For all other fields, display the value -->
                    <p class="mt- mb-1 font-medium text-md text-color-primary">
                      <span
                        *ngIf="
                          col.field !== 'suspension_status' &&
                          col.field !== 'employee_id' &&
                          col.field !== 'start_date' &&
                          col.field !== 'end_date' &&
                          col.field !== 'reason'
                        "
                      >
                        {{ suspension[col.field] }}
                      </span>
                    </p>
                  </div>
                </td>

                <td>
                  <!-- test -->
                  <button
                    type="button"
                    pButton
                    icon="pi pi-download"
                    [ngStyle]="{ color: '#3f51b5' }"
                    class="p-button-text p-button-success custom-blue-icon"
                    (click)="GeneratedSuspensionNotice(suspension.id)"
                  ></button>
                  <button
                    *ngIf="
                      suspension.suspension_status !==
                      'suspension period completed' &&
                      suspension.suspension_status !==
                      'unpaid suspension period completed'
                      
                    "
                    type="button"
                    pButton
                    icon="pi pi-pencil"
                    class="p-button-text p-button-success"
                    (click)="
                      editSuspension(suspension); $event.stopPropagation()
                    "
                  ></button>

                  <!-- <button
                    type="button"
                    pButton
                    icon="pi pi-trash"
                    class="p-button-text p-button-danger"
                    (click)="deleteSuspension(suspension); $event.stopPropagation()"
                  ></button> -->
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #noDataTemplate>
    <div class="flex p-3 flex-column align-items-center justify-content-center">
      <p>No data available at present</p>
    </div>
  </ng-template>
  <div *ngIf="this.Suspensions$ | async; else loadedContent">
    <div class="surface-card shadow-2 flex p-3 flex-column">
      <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>
