import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { ApiService } from "src/app/_core/_services/api.service";
import { catchError, switchMap, tap } from "rxjs/operators"; // Import tap operator if not already imported
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class FilesService {
  private filesSubject = new BehaviorSubject<any[]>([]);
  files$ = this.filesSubject.asObservable();

  constructor(private api: ApiService, private http: HttpClient) {}

  uploadFile(formData: FormData, incident_id: number): Observable<any> {
    // Return the observable from postAPI
    return this.api
      .postFileAPI(`files/upload-file/${incident_id}`, formData)
      .pipe(
        tap((response: any) => {
          console.log("response", response.body);
          this.filesSubject.next(response.body);
        })
      );
  }

  uploadHearingFile(formData: FormData, hearing_id: number): Observable<any> {
    return this.api
      .postFileAPI(`files/upload-hearing-file/${hearing_id}`, formData)
      .pipe(
        tap((response: any) => {
          console.log("response", response.body);
          this.filesSubject.next(response.body);
        })
      );
  }

  getFiles(): Observable<any> {
    return this.api.getAPI("files/get-files");
  }

  downloadFile(
    unique_file_reference: string,
    original_filename: string
  ): Observable<any> {
    return this.api
      .getFileAPI(`files/download-file/${unique_file_reference}`, "")
      .pipe(
        tap((response: any) => {
          const blob: Blob = response.body as Blob;
          const a = document.createElement("a");

          a.download = original_filename || `${unique_file_reference}`;
          a.href = window.URL.createObjectURL(blob);
          a.click();

          window.URL.revokeObjectURL(a.href); // Clean up
        }),
        catchError((error) => {
          console.error("Download error:", error);
          return throwError(error);
        })
      );
  }

  get_user_files(user_id: string): Observable<any> {
    return this.api.getAPI(`files/get-files/${user_id}`);
  }

  refreshFiles(): Observable<File[]> {
    return this.getFiles().pipe(
      tap((files) => {
        this.filesSubject.next(files);
      })
    );
  }
  //Mix up names was  downloadGeneratedFile
  downloadGeneratedHearingFile(incidentId: number): Observable<Blob> {
    const url = `files/generate-warning-document`;
    return this.api.getFileAPI(url, incidentId).pipe(
      catchError((error) => {
        console.error("File download error:", error);
        throw error;
      })
    );
  }
  //Mix up names was  downloadGeneratedHearingFile
  downloadGeneratedFile(
    file_id: number,
    incident_id: number
  ): Observable<Blob> {
    const url = `files/download-generated-file/${incident_id}`;
    return this.api.getFileAPI(url, file_id).pipe(
      catchError((error) => {
        console.error("File download error:", error);
        throw error;
      })
    );
  }
  //generating hearing document
  //Mix up names was  downloadGeneratedFile
  downloadGeneratedHearingNotice(hearingId: number): Observable<Blob> {
    const url = `files/generate-hearing-notice`;
    return this.api.getFileAPI(url, hearingId).pipe(
      catchError((error) => {
        console.error("File download error:", error);
        throw error;
      })
    );
  }
  //test
  GenerateSuspensionNotice(suspensionId: number): Observable<Blob> {
    const url = `files/generate-suspension-notice`;
    return this.api.getFileAPI(url, suspensionId).pipe(
      catchError((error) => {
        console.error("File download error:", error);
        throw error;
      })
    );
  }

  //Mix up names was  downloadGeneratedHearingFile
  downloadGeneratedNotice(
    file_id: number,
    hearing_id: number
  ): Observable<Blob> {
    const url = `files/download-generated-notice/${hearing_id}`;
    return this.api.getFileAPI(url, file_id).pipe(
      catchError((error) => {
        console.error("File download error:", error);
        throw error;
      })
    );
  }
}
