import { Injectable, Input } from "@angular/core";
import { Observable, Subject, map } from "rxjs";
import { Action } from "../_models/action";
import { ApiService } from "src/app/_core/_services/api.service";

@Injectable({
  providedIn: "root",
})
export class ActionService {
  @Input() action: any;
  @Input() actionObservable$: Subject<any> = new Subject<any>();

  actionQuery$: any;

  constructor(public api: ApiService) {}

  getActions(): Observable<Action[]> {
    return this.api.getAPI<Action[]>("actions").pipe(map((res) => res.body));
  }

  getActionsByIncidentId(incidentId: number): Observable<Action[]> {
    return this.api
      .getAPI<Action[]>(`actions/incident/${incidentId}`)
      .pipe(map((res) => res.body.data));
  }

  postAction(actionData: Action): Observable<Action> {
    return this.api
      .postAPI<Action>("actions", actionData)
      .pipe(map((res) => res.body));
  }
}
