<div
  *ngIf="this.createIncidents$ | async; else loadedContent"
  class="formgrid p-fluid px-2 py-5 md:px-3 lg:px-4"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
  </div>
</div>

<ng-template #loadedContent>
  <form [formGroup]="incidentForm" (ngSubmit)="onAddIncidentFormSubmit()">
    <div class="surface-section px-2 py-5 md:px-3 lg:px-4">
      <div class="grid formgrid p-fluid">
        <!-- employees Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employee_id">Employee</label>
          <p-dropdown
            [options]="employees"
            optionLabel="label"
            optionValue="value"
            formControlName="employee_id"
            placeholder="Select an employee"
            [filter]="true"
            filterPlaceholder="Search employees by code or name"
          ></p-dropdown>
          <div
            *ngIf="
              incidentForm.controls['employee_id'].invalid &&
              (incidentForm.controls['employee_id'].dirty ||
                incidentForm.controls['employee_id'].touched)
            "
            class="p-error"
          >
            Employee is required.
          </div>
        </div>
        <!-- Date of Incident Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentDate">Date Of Incident</label>
          <p-calendar
            type="text"
            id="incidentDate"
            name="incidentDate"
            class="w-full"
            formControlName="date"
            dateFormat="yy-mm-dd"
            [inline]="false"
            dataType="string"
            appendTo="body"
          ></p-calendar>
        </div>
        <!-- Transgression Dropdown -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="transgression_id">Transgression</label>
          <p-dropdown
            [options]="transgressions"
            optionLabel="label"
            optionValue="value"
            formControlName="transgression_id"
            placeholder="Select a transgression"
            [filter]="true"
            filterPlaceholder="Search transgressions by category or starting word"
          >
            <ng-template let-item pTemplate="item">
              <div class="flex justify-between custom-dropdown-item">
                <span>{{ item.label }}</span>
              </div>
            </ng-template>
          </p-dropdown>
          <!-- [panelStyle]="{ 'max-width': '47%' }" -->
          <div
            *ngIf="
              incidentForm.controls['transgression_id'].invalid &&
              (incidentForm.controls['transgression_id'].dirty ||
                incidentForm.controls['transgression_id'].touched)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Transgression</strong> is required.
            </small>
          </div>
        </div>

        <!-- Category Field -->
        <!-- <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentCategory">Category</label>
          <input
            formControlName="category"
            type="text"
            class="form-control"
            id="incidentCategory"
            placeholder="category provided by transgression"
            pInputTextarea
            rows="5"
            cols="30"
            readonly
            style="
              pointer-events: none;
              background-color: #f8f9fa;
              color: #495057;
            "
          />
          <div
            *ngIf="
              incidentForm.get('category')?.invalid &&
              (incidentForm.get('category')?.touched ||
                incidentForm.get('category')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Category</strong> is required</small
            >
          </div>
        </div> -->
        <!-- Action Taken Field -->
        <!-- <div class="field mb-4 col-12 lg:col-6">
          <label for="incidentActionTaken">Action Taken</label>
          <input
            formControlName="action_taken"
            type="text"
            class="form-control"
            id="incidentActionTaken"
            placeholder="Enter action taken"
            pInputTextarea
            rows="5"
            cols="30"
            readonly
          />
        </div> -->

        <!-- Resolution Field -->
        <!-- <div class="field mb-4 col-12 lg:col-12">
          <label for="incidentResolution">Resolution</label>
          <p-dropdown
            formControlName="resolution"
            [options]="resolutionOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Select a Resolution"
            [disabled]="true"
          ></p-dropdown>
          <div
            *ngIf="
              incidentForm.get('resolution')?.invalid &&
              (incidentForm.get('resolution')?.touched ||
                incidentForm.get('resolution')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Resolution</strong> is required</small
            >
          </div>
        </div> -->
        <div
          class="flex flex-column lg:flex-row field mb-4 col-12 lg:col-12"
          *ngIf="isInEditMode && incident?.files?.length"
        >
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Evidence Files</div>
            <ng-container
              *ngIf="evidencesFiles.length === 0; else evidenceFilesList"
            >
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #evidenceFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of evidencesFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadFile(
                      file.unique_file_reference,
                      file.original_filename
                    );
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>
          <!-- Admin files -->
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Admin Files</div>
            <ng-container *ngIf="adminFiles.length === 0; else adminFilesList">
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #adminFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of adminFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadFile(
                      file.unique_file_reference,
                      file.original_filename
                    );
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>

          <!-- Generated files -->
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Generated Files</div>
            <ng-container
              *ngIf="generatedFiles.length === 0; else generatedFilesList"
            >
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #generatedFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of generatedFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadGeneratedFile(file.id, incident.id);
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- Description Field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="incidentDescription">Description</label>
          <textarea
            formControlName="description"
            type="text"
            class="form-control"
            id="incidentDescription"
            placeholder="Enter description"
            pInputTextarea
            [autoResize]="true"
            rows="5"
            cols="30"
            class="form-control p-inputtext p-component"
          ></textarea>
          <div
            *ngIf="
              incidentForm.get('description')?.invalid &&
              (incidentForm.get('description')?.touched ||
                incidentForm.get('description')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Description</strong> is required</small
            >
          </div>
        </div>
        <!-- File Upload Field -->
        <div class="field mb-4 col-12 lg:col-12">
          <br />
          <label for="file_id">Upload File</label>
          <p-fileUpload
            name="file[]"
            #fubadvanced
            [multiple]="true"
            [customUpload]="true"
            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            [maxFileSize]="1000000"
            (uploadHandler)="onFileUpload($event, fubadvanced)"
          >
            <ng-template pTemplate="content">
              <div *ngIf="uploadedFiles.length">
                <div
                  class="flex p-1 flex-column"
                  *ngFor="let file of uploadedFiles"
                >
                  <div class="flex align-items-center">
                    <span class="block pi pi-file mr-2"></span>
                    <span class="text-900"
                      >{{ file.original_filename }} ({{
                        file.file_size
                      }}
                      bytes)</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </p-fileUpload>
        </div>
        <!-- Submit Button -->
        <div class="field col-12">
          <p-button
            styleClass="bg-blue-400"
            type="submit"
            label="Submit"
            class="w-6"
            [loading]="loading"
          ></p-button>
        </div>
      </div>
    </div>
  </form>
</ng-template>
