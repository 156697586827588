import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Transgression } from "src/app/_share/_models/transgression";
import { TransgressionService } from "src/app/_share/_services/transgression.service";

@Component({
  selector: "app-create-transgression",
  templateUrl: "./create-transgression.component.html",
  styleUrls: ["./create-transgression.component.scss"],
})
export class CreateTransgressionComponent implements OnInit {
  createTransgressions$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  transgressionForm: FormGroup = this.formBuilder.group({
    category: ["", Validators.required],
    standard: ["", Validators.required],
    offence: ["", Validators.required],
    offence_penalty: [
      "",
      [Validators.required, Validators.pattern("^[0-9]*$")],
    ],
    disciplinary_code: ["", Validators.required],
  });

  editFormData: Transgression | undefined;
  isInEditMode: boolean = false;
  loading: boolean = false;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public transgressionService: TransgressionService,
    public formBuilder: FormBuilder
  ) {}

  categoryOptions = [
    { label: "Category 1", value: 1 },
    { label: "Category 2", value: 2 },
    { label: "Category 3", value: 3 },
    { label: "Category 4", value: 4 },
  ];
  disciplinaryCodeOptions = [
    { label: "Code 1", value: "Code 1" },
    { label: "Code 2", value: "Code 2" },
    { label: "Code 3", value: "Code 3" },
    { label: "Code 4", value: "Code 4" },
    { label: "Code 5", value: "Code 5" },
    { label: "Code 6", value: "Code 6" },
    { label: "Code 7", value: "Code 7" },
    { label: "Code 8", value: "Code 8" },
    { label: "Code 9", value: "Code 9" },
    { label: "Code 10", value: "Code 10" },
    { label: "Code 11", value: "Code 11" },
    { label: "Code 12", value: "Code 12" },
  ];

  updatePenalty(categoryValue: number) {
    const selectedCategory = this.categoryOptions.find(
      (option) => option.value === categoryValue
    );
    if (selectedCategory) {
      // Set the offence penalty based on the selected category
      this.transgressionForm.patchValue({
        offence_penalty: selectedCategory.value,
        category: selectedCategory.label,
      });
    }
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.createTransgressions$.next(false);
    }, 1000);
    this.updateFormData();
  }

  // Updates the form data if in edit mode
  updateFormData() {
    this.editFormData = this.config.data?.formdata;
    if (this.editFormData) {
      this.transgressionForm.patchValue(this.editFormData);
      this.isInEditMode = true;
    } else {
      this.isInEditMode = false;
    }
  }

  onAddTransgressionFormSubmit() {
    this.loading = true;
    if (this.transgressionForm.invalid) {
      this.transgressionForm.markAllAsTouched();
      return;
    }
    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      this.transgressionService
        .updateTransgression(this.editFormData.id, this.transgressionForm.value)
        .subscribe({
          next: (res: any) => {
            console.log("Transgression updated successfully", res);
            this.ref.close(this.transgressionForm.value);
          },
          error: (err: any) => {
            console.error("Error updating transgression", err);
          },
        });
    } else {
      this.transgressionService
        .addTransgression(this.transgressionForm.value)
        .subscribe({
          next: (res: any) => {
            console.log("Transgression added successfully", res);
            this.transgressionService.refreshTransgressions();
            this.ref.close(this.transgressionForm.value);
          },
          error: (err: any) => {
            console.error("Error adding transgression", err);
          },
        });
    }
  }
}
