<div class="flex flex-column md:flex-column">
  <div class="grid">
    <!-- incidents -->
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Incidents</span>
            <div
              class="text-900 font-medium text-xl"
              *ngIf="
                this.incidents$ | async as incidentsCount;
                else loadingIncidentsCount
              "
            >
              {{ incidentsCount.count }}
            </div>
            <ng-template #loadingIncidentsCount>
              <p-skeleton width="100%" height="1.5rem"></p-skeleton>
            </ng-template>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-orange-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-exclamation-circle text-red-500 text-xl"></i>
          </div>
        </div>
        <span
          class="text-green-500 font-medium"
          *ngIf="
            this.incidents$ | async as incidentsCount;
            else loadingIncidentsOpenCount
          "
          >{{ (incidents$ | async).open_count }}</span
        >
        <ng-template #loadingIncidentsOpenCount>
          <p-skeleton width="1.5rem" height="1.5rem"></p-skeleton>
        </ng-template>
        <span class="text-500"> currently OPEN</span>
      </div>
    </div>
    <!-- hearings -->
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Hearings</span>
            <div
              *ngIf="
                hearings$ | async as hearingsCount;
                else loadingHearingsCount
              "
              class="text-900 font-medium text-xl"
            >
              {{ hearingsCount.count }}
            </div>
            <ng-template #loadingHearingsCount>
              <p-skeleton width="100%" height="1.5rem"></p-skeleton>
            </ng-template>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-orange-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-user-minus text-red-500 text-xl"></i>
          </div>
        </div>
        <span
          class="text-green-500 font-medium"
          *ngIf="hearings$ | async as hearingsCount"
          >{{ hearingsCount.open_count }}</span
        >
        <span class="text-500"> currently OPEN</span>
      </div>
    </div>
    <!-- transgressions -->
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3"
              >Transgression Types</span
            >
            <div
              class="text-900 font-medium text-xl"
              *ngIf="
                this.transgressions$ | async as transgressionsCount;
                else loadingTransgressionsCount
              "
            >
              {{ transgressionsCount.count }}
            </div>
            <ng-template #loadingTransgressionsCount>
              <p-skeleton width="100%" height="1.5rem"></p-skeleton>
            </ng-template>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-yellow-200"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i
              class="pi pi-fw pi-exclamation-triangle text-orange-500 text-xl"
            ></i>
          </div>
        </div>
        <span class="text-500">4 categories</span>
      </div>
    </div>
    <!-- employees -->
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Employees</span>
            <div
              class="text-900 font-medium text-xl"
              *ngIf="
                employees$ | async as employeeCountObject;
                else loadingEmployeeCount
              "
            >
              {{ employeeCountObject.count }}
            </div>
            <ng-template #loadingEmployeeCount>
              <p-skeleton width="100%" height="1.5rem"></p-skeleton>
            </ng-template>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-blue-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-users text-blue-500 text-xl"></i>
          </div>
        </div>
        <span
          class="text-green-500 font-medium"
          *ngIf="employees$ | async as employeeCountObject"
          >{{ employeeCountObject.new_count }} new</span
        >
        <ng-template #loadingNewCount>
          <p-skeleton width="1.5rem" height="1.5rem"></p-skeleton>
        </ng-template>
        <span class="text-500"> in the last month</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-3" *ngIf="false">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Comments</span>
            <div class="text-900 font-medium text-xl">152 Unread</div>
          </div>
          <div
            class="flex align-items-center justify-content-center bg-purple-100 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-comment text-purple-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">85 </span>
        <span class="text-500">responded</span>
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 lg:col-6">
      <div class="surface-card shadow-2 border-round p-4 h-full">
        <div class="flex align-items-center justify-content-between mb-4">
          <div class="text-900 font-medium text-xl">Suspended Employees</div>
          <div
            class="flex align-items-center justify-content-center bg-yellow-200 border-round"
            style="width: 2.5rem; height: 2.5rem"
          >
            <i class="pi pi-ban text-red-500 text-xl"></i>
          </div>
        </div>
        <!-- Today Section -->
        <span class="block text-600 font-medium mb-3"
          >TODAY ({{ today | date : "shortDate" }})</span
        >
        <div
          class="scrollable-list"
          style="max-height: 200px; overflow-y: auto;"
        >
          <ul class="p-0 mx-0 mt-0 mb-4 list-none">
            <ng-container
              *ngIf="suspendedEmployeesToday$.length > 0; else noSuspendedToday"
            >
              <ng-container *ngFor="let employee of suspendedEmployeesToday$">
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-user text-xl text-blue-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium">
                    {{ employee.employee_code }}<br />
                    {{ employee.first_name }} {{ employee.last_name }}
                    <span class="text-700 font-normal">
                      - Suspended since
                      <span class="text-primary font-medium">{{
                        employee.suspension_date | date : "short"
                      }}</span>
                    </span>
                  </span>
                  <!-- Red Bracket Showing Days Suspended -->
                  <!-- <span class="p-1 text-sm font-bold text-white"
                        style="background-color: red; border-radius: 4px; margin-left: auto;">
                    Suspended for {{ getDaysSinceSuspended(employee.suspension_date) }} Days
                  </span> -->
                </li>
              </ng-container>
            </ng-container>
            <ng-template #noSuspendedToday>
              <li class="flex align-items-center py-2">
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-check text-xl text-green-500"></i>
                </div>
                <span class="text-700 line-height-3"
                  >No suspended employees today.</span
                >
              </li>
            </ng-template>
          </ul>
        </div>
        <!-- All Suspended Employees Section -->
        <span class="block text-600 font-medium mb-3"
          >ALL SUSPENDED EMPLOYEES</span
        >
        <div
          class="scrollable-list"
          style="max-height: 300px; overflow-y: auto;"
        >
          <ul class="p-0 mx-0 mt-0 mb-4 list-none">
            <ng-container
              *ngIf="
                allSuspendedEmployees$.length > 0;
                else noSuspendedEmployees
              "
            >
              <ng-container *ngFor="let employee of allSuspendedEmployees$">
                <li
                  class="flex align-items-center py-2 border-bottom-1 surface-border"
                >
                  <div
                    class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0"
                  >
                    <i class="pi pi-user text-xl text-blue-500"></i>
                  </div>
                  <span class="text-900 line-height-3 font-medium">
                    {{ employee.employee_code }}<br />
                    {{ employee.first_name }} {{ employee.last_name }}
                    <span class="text-700 font-normal">
                      - Suspended since
                      <span class="text-primary font-medium">{{
                        employee.suspension_date | date : "short"
                      }}</span>
                    </span>
                  </span>
                  <span
                    class="p-1 text-sm font-bold text-black"
                    style="
                      background-color: rgb(255, 196, 0);
                      border-radius: 4px;
                      margin-left: auto;
                      margin-right: 20px;
                    "
                  >
                    Has been suspended for
                    {{ getDaysSinceSuspended(employee.suspension_date) }} Days
                  </span>
                </li>
              </ng-container>
            </ng-container>
            <ng-template #noSuspendedEmployees>
              <li class="flex align-items-center py-2">
                <div
                  class="w-3rem h-3rem flex align-items-center justify-content-center bg-green-100 border-circle mr-3 flex-shrink-0"
                >
                  <i class="pi pi-check text-xl text-green-500"></i>
                </div>
                <span class="text-700 line-height-3"
                  >No suspended employees.</span
                >
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
