import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, repeat, shareReplay, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(
    private http: HttpClient,
    public authenticationService: AuthenticationService
  ) {}

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  httpFileOptions = {
    headers: new HttpHeaders({
      "Content-Type": "multipart/form-data",
    }),
  };

  getAPI<Type>(
    endpoint: string,
    queryParams?: any,
    pollInterval?: number
  ): Observable<any> {
    // console.log(
    //   `GET Request to: ${environment.apiUrl}/${endpoint}`,
    //   queryParams
    // );
    let parameters = {};
    if (pollInterval) {
      if (queryParams) {
        parameters = queryParams;
      }
      return this.http
        .get<Type>(`${environment.apiUrl}/${endpoint}`, {
          observe: "response",
          responseType: "json",
          params: parameters,
        })
        .pipe(catchError(this.handleError), repeat({ delay: pollInterval }));
    } else {
      if (queryParams) {
        parameters = queryParams;
      }
      return this.http
        .get<Type>(`${environment.apiUrl}/${endpoint}`, {
          observe: "response",
          responseType: "json",
          params: parameters,
        })
        .pipe(catchError(this.handleError), shareReplay(1));
    }
  }

  postAPI<Type>(endpoint: string, data: any): Observable<any> {
    // console.log(`POST Request to: ${environment.apiUrl}/${endpoint}`, data);
    return this.http
      .post<Type>(`${environment.apiUrl}/${endpoint}`, data, {
        observe: "response",
        responseType: "json",
      })
      .pipe(catchError(this.handleError));
  }

  deleteAPI<Type>(endpoint: string, query_id: number): Observable<any> {
    // console.log(
    //   `DELETE Request to: ${environment.apiUrl}/${endpoint}/${query_id}`
    // );
    return this.http
      .delete<Type>(`${environment.apiUrl}/${endpoint}/${query_id}`, {
        observe: "response",
        responseType: "json",
      })
      .pipe(catchError(this.handleError));
  }

  deleteAllAPI<Type>(endpoint: string): Observable<any> {
    // console.log(`DELETE ALL Request to: ${environment.apiUrl}/${endpoint}/`);
    return this.http
      .delete<Type>(`${environment.apiUrl}/${endpoint}/`, {
        observe: "response",
        responseType: "json",
      })
      .pipe(catchError(this.handleError));
  }

  putAPI<Type>(endpoint: string, query_id: number, data: any): Observable<any> {
    // console.log(
    //   `PUT Request to: ${environment.apiUrl}/${endpoint}/${query_id}`,
    //   data
    // );
    return this.http
      .put<Type>(`${environment.apiUrl}/${endpoint}/${query_id}`, data, {
        observe: "response",
        responseType: "json",
      })
      .pipe(catchError(this.handleError));
  }
  patchAPI<Type>(
    endpoint: string,
    query_id: number,
    data: any
  ): Observable<any> {
    // console.log(
    //   `PATCH Request to: ${environment.apiUrl}/${endpoint}/${query_id}`,
    //   data
    // );
    return this.http
      .patch<Type>(`${environment.apiUrl}/${endpoint}/${query_id}`, data, {
        observe: "response",
        responseType: "json",
      })
      .pipe(catchError(this.handleError));
  }

  postFileAPI<Type>(endpoint: string, data: any): Observable<any> {
    // console.log(
    //   `POST File Request to: ${environment.apiUrl}/${endpoint}`,
    //   data
    // );
    return this.http
      .post<Type>(`${environment.apiUrl}/${endpoint}`, data, {
        observe: "response",
        responseType: "json",
      })
      .pipe(catchError(this.handleError));
  }
  //p0: any, p1:{ extension: string;, }
  getFileAPI<Type>(endpoint: string, query_id: any): Observable<any> {
    // console.log(
    //   `GET File Request to: ${environment.apiUrl}/${endpoint}/${query_id}`
    // );
    return this.http
      .get(`${environment.apiUrl}/${endpoint}/${query_id}`, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "";
    console.log("error", error);

    if (error.error instanceof ErrorEvent) {
      // Client-side error
      errorMessage = `An error occurred: ${error.error.message}`;
    } else {
      // Server-side error
      errorMessage = `Server returned code: ${error.status}, error message is: ${error.error.message}`;
    }

    // Optionally handle specific error codes or show alerts
    if (error.status === 401) {
      console.error("Unauthorized error:", errorMessage);
    } else {
      console.error("Error:", errorMessage);
    }

    // Bubble up the error message
    return throwError(error);
  }
}
