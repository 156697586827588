<div
  *ngIf="this.createLeaves$ | async; else loadedContent"
  class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8"
>
  <!-- Skeleton loader or loading state -->
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
  </div>
  <div class="field col-12">
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
    <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
  </div>
</div>

<ng-template #loadedContent>
  <form [formGroup]="leaveForm" (ngSubmit)="onAddleaveFormSubmit()">
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8">
      <div class="grid formgrid p-fluid">
        <!-- employees Field -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="employee_id">Employee Details</label>
          <p-dropdown
            [options]="employees"
            optionLabel="label"
            optionValue="value"
            formControlName="employee_id"
            placeholder="Select an employee"
            [filter]="true"
            filterPlaceholder="Search employees by code or name"
          ></p-dropdown>
          <div
            *ngIf="
              leaveForm.controls['employee_id'].invalid &&
              (leaveForm.controls['employee_id'].dirty ||
                leaveForm.controls['employee_id'].touched)
            "
            class="p-error"
          >
            <small class="p-error block"
              ><strong>Employee details required</strong> is required</small
            >
          </div>
        </div>

        <!-- Leave action Field -->
          <!-- Call onLeaveTypeChange when the dropdown value changes -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="leave_action_type">Leave Type</label>
          <p-dropdown
            formControlName="leave_action_type"
            [options]="LeaveTypeOptions"
            placeholder="Select leave type "
            id="leave_action_type"
            (onChange)="onLeaveTypeChange($event)"
          ></p-dropdown>
          <div
            *ngIf="
              leaveForm.get('leave_action_type')?.invalid &&
              (leaveForm.get('leave_action_type')?.touched ||
                leaveForm.get('leave_action_type')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Suspension Status</strong> is required</small
            >
          </div>
        </div>
        <!-- start_period Field -->
         <!-- Call onDateChange when the start date is changed -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="start_period">Start Period</label>
          <p-calendar
            [showIcon]="true"
            type="text"
            id="start_period"
            name="start_period"
            class="w-full"
            formControlName="start_period"
            dateFormat="yy-mm-dd"
            [inline]="false"
            dataType="string"
            appendTo="body"
            placeholder="Please enter leave start date"
            (onSelect)="onDateChange($event)"
          ></p-calendar>
        </div>
        <!-- end_period Field -->
        <!-- Call onDateChange when the end date is changed -->
        <div class="field mb-4 col-12 lg:col-6">
          <label for="end_period">End Period</label>
          <p-calendar
            [showIcon]="true"
            type="text"
            id="end_period"
            name="end_period"
            class="w-full"
            formControlName="end_period"
            dateFormat="yy-mm-dd"
            [inline]="false"
            dataType="string"
            appendTo="body"
            placeholder="Please enter return date"
            (onSelect)="onDateChange($event)" 
          ></p-calendar>
        </div>
        <!-- sick leave amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="sick_leave_amount">Sick Leave Taken</label>
          <input
            formControlName="sick_leave_amount"
            type="text"
            class="form-control"
            id="sick_leave_amount"
            pInputTextarea
            [readOnly]="true"
            placeholder="System will automatically fill the value"
            style="background-color: #f5f5f5; pointer-events: none;"
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('sick_leave_amount')?.invalid &&
              (leaveForm.get('sick_leave_amount')?.touched ||
                leaveForm.get('sick_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>sick leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- annual_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="annual_leave_amount">Annual Leave Taken</label>
          <input
            formControlName="annual_leave_amount"
            type="text"
            class="form-control"
            id="annual_leave_amount"
            [readOnly]="true"
            placeholder="System will automatically fill the value"
            style="background-color: #f5f5f5; pointer-events: none;"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('annual_leave_amount')?.invalid &&
              (leaveForm.get('annual_leave_amount')?.touched ||
                leaveForm.get('annual_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>annual leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- maternity_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="maternity_leave_amount">Maternity Leave Taken</label>
          <input
            formControlName="maternity_leave_amount"
            type="text"
            class="form-control"
            id="maternity_leave_amount"
            [readOnly]="true"
            placeholder="System will automatically fill the value"
            style="background-color: #f5f5f5; pointer-events: none;"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('maternity_leave_amount')?.invalid &&
              (leaveForm.get('maternity_leave_amount')?.touched ||
                leaveForm.get('maternity_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>maternity leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- family_responsiblity_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="family_responsiblity_leave_amount"
            >Family Responsiblity Leave Taken</label
          >
          <input
            formControlName="family_responsiblity_leave_amount"
            type="text"
            class="form-control"
            id="family_responsiblity_leave_amount"
            [readOnly]="true"
            placeholder="System will automatically fill the value"
            style="background-color: #f5f5f5; pointer-events: none;"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('family_responsiblity_leave_amount')?.invalid &&
              (leaveForm.get('family_responsiblity_leave_amount')?.touched ||
                leaveForm.get('family_responsiblity_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>Family Responsiblity Leave Amount</strong> is
              required</small
            >
          </div>
        </div>
        <!-- unpaid_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="unpaid_leave_amount">Unpaid Leave Taken</label>
          <input
            formControlName="unpaid_leave_amount"
            type="text"
            class="form-control"
            id="unpaid_leave_amount"
            [readOnly]="true"
            placeholder="System will automatically fill the value"
            style="background-color: #f5f5f5; pointer-events: none;"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('unpaid_leave_amount')?.invalid &&
              (leaveForm.get('unpaid_leave_amount')?.touched ||
                leaveForm.get('unpaid_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>unpaid leave amount</strong> is required</small
            >
          </div>
        </div>
        <!-- study_leave_amount -->
        <div class="field mb-4 col-12 lg:col-4">
          <label for="study_leave_amount">Study Leave Taken</label>
          <input
            formControlName="study_leave_amount"
            type="text"
            class="form-control"
            id="study_leave_amount"
            [readOnly]="true"
            placeholder="System will automatically fill the value"
            style="background-color: #f5f5f5; pointer-events: none;"
            pInputTextarea
            rows="5"
            cols="30"
          />
          <div
            *ngIf="
              leaveForm.get('study_leave_amount')?.invalid &&
              (leaveForm.get('study_leave_amount')?.touched ||
                leaveForm.get('study_leave_amount')?.dirty)
            "
            class="px-1 py-1"
          >
            <small class="p-error block"
              ><strong>unpaid leave amount</strong> is required</small
            >
          </div>
        </div>
        <div
          class="flex flex-column lg:flex-row field mb-4 col-12 lg:col-12"
          *ngIf="isInEditMode"
        >
          <!-- Evidence files -->
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Evidence Files</div>
            <ng-container
              *ngIf="evidencesFiles.length === 0; else evidenceFilesList"
            >
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #evidenceFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of evidencesFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadFile(
                      file.unique_file_reference,
                      file.original_filename
                    );
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>

          <!-- Admin files -->
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Admin Files</div>
            <ng-container *ngIf="adminFiles.length === 0; else adminFilesList">
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #adminFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of adminFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadFile(
                      file.unique_file_reference,
                      file.original_filename
                    );
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>

          <!-- Generated files -->
          <div class="col-4 p-3">
            <div class="text-700 font-medium text-lg mb-3">Generated Files</div>
            <ng-container
              *ngIf="generatedFiles.length === 0; else generatedFilesList"
            >
              <div
                class="text-500 font-medium border-top-1 surface-border p-3 text-center"
              >
                No files attached
              </div>
            </ng-container>
            <ng-template #generatedFilesList>
              <div
                class="flex md:align-items-center md:justify-content-between border-top-1 surface-border p-3 flex-column md:flex-row"
                *ngFor="let file of generatedFiles"
              >
                <div class="flex align-items-center">
                  <span class="block pi pi-file mr-2"></span>
                  <div>
                    <div class="text-900 mr-2">
                      {{ file.original_filename }}
                    </div>
                    <div class="text-500 text-xs">
                      uploaded {{ file.date_uploaded | dateAsAgo }}
                    </div>
                  </div>
                </div>
                <a
                  href="javascript:void(0);"
                  pButton
                  pRipple
                  icon="pi pi-download"
                  class="p-button-outlined mt-2 md:mt-0"
                  style="text-decoration: none"
                  (click)="
                    downloadGeneratedFile(file.id, leave.id);
                    $event.stopPropagation()
                  "
                ></a>
              </div>
            </ng-template>
          </div>
        </div>
        <!-- File Upload Field -->
        <div class="field mb-4 col-12 lg:col-12">
          <label for="file_id">File</label>
          <p-fileUpload
            name="file[]"
            #fubadvanced
            [multiple]="true"
            [customUpload]="true"
            accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            [maxFileSize]="1000000"
            (uploadHandler)="onFileUpload($event, fubadvanced)"
          >
            <ng-template pTemplate="content">
              <div *ngIf="uploadedFiles.length">
                <div
                  class="flex p-1 flex-column"
                  *ngFor="let file of uploadedFiles"
                >
                  <div class="flex align-items-center">
                    <span class="block pi pi-file mr-2"></span>
                    <span class="text-900"
                      >{{ file.original_filename }} ({{
                        file.file_size
                      }}
                      bytes)</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
          </p-fileUpload>
        </div>

        <div class="field col-12">
          <p-button
            styleClass="bg-blue-400"
            type="submit"
            label="Submit"
            class="w-6"
          ></p-button>
          <!-- [loading]="loading" -->
        </div>
      </div>
    </div>
  </form>
</ng-template>
