import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { SuspensionService } from "src/app/_share/_services/suspensions.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";

@Component({
  selector: "app-create-suspension",
  templateUrl: "./create-suspension.component.html",
  styleUrls: ["./create-suspension.component.scss"],
})
export class CreateSuspensionComponent implements OnInit {
  createSuspensions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  uploadedFiles: any[] = [];
  employees: any[] = [];

  suspensionForm: FormGroup = this.formBuilder.group({
    employee_id: ["", Validators.required],
    suspension_status: ["", Validators.required],
    start_date: ["", Validators.required], // New start date
    end_date: ["", Validators.required], // New end date
    reason: ["", Validators.required], // New suspension reason field
  });

  suspensionStatusOptions: { label: string; value: string }[] = [
    // { label: "Active", value: "active" },
    { label: "Suspended", value: "suspended" },
    { label: "unpaid suspension", value: "unpaid suspension" },
  ];
  editFormData: any;
  isInEditMode: boolean = false;
  suspensionId: number | undefined;
  suspension: any;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private employeeService: EmployeeService,
    private suspensionService: SuspensionService
  ) {}

  ngOnInit(): void {
    this.editFormData = this.config.data?.formData;
    this.loadEmployees();
    setTimeout(() => {
      this.createSuspensions$.next(false);
    }, 1000);
    this.updateFormData();
  }

  updateFormData() {
    if (this.editFormData) {
      const startDate = this.editFormData.start_date
        ? new Date(this.editFormData.start_date)
        : null;
      const endDate = this.editFormData.end_date
        ? new Date(this.editFormData.end_date)
        : null;

      this.suspensionForm.patchValue({
        employee_id: this.editFormData.employee_id,
        suspension_status: this.editFormData.suspension_status,
        start_date: startDate,
        end_date: endDate,
        reason: this.editFormData.reason,
      });

      this.isInEditMode = true;
    }
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees.map((employee: any) => ({
        label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
        value: employee.id,
      }));
    });
  }

  onSuspensionFormSubmit(): void {
    if (this.suspensionForm.invalid) {
      return;
    }

    const suspensionData = {
      ...this.suspensionForm.value,
      files: this.uploadedFiles,
    };

    if (this.isInEditMode) {
      this.suspensionService
        .updateSuspension(this.editFormData.id, suspensionData)
        .subscribe({
          next: (updatedSuspension) => {
            this.ref.close(updatedSuspension); // Pass updated data back
          },
          error: (err) => {
            console.error("Failed to update suspension", err);
          },
        });
    } else {
      this.suspensionService.addSuspension(suspensionData).subscribe({
        next: () => {
          this.ref.close(suspensionData);
        },
        error: (err) => {
          console.error("Failed to create suspension", err);
        },
      });
    }
  }

  // xsonSuspensionFormSubmit(): void {
  //   if (this.suspensionForm.invalid) {
  //     return;
  //   }

  //   const suspensionData = {
  //     ...this.suspensionForm.value,
  //     files: this.uploadedFiles,
  //   };

  //   this.suspensionService.addSuspension(suspensionData).subscribe({
  //     next: () => {
  //       this.ref.close(suspensionData);
  //     },
  //     error: (err) => {
  //       console.error("Failed to create suspension", err);
  //     },
  //   });
  // }

  // xonSuspensionFormSubmit(): void {
  //   if (this.suspensionForm.invalid) {
  //     return;
  //   }
  //   const suspensionData = {
  //     ...this.suspensionForm.value,
  //     files: this.uploadedFiles,
  //   };

  //   this.suspensionService.addSuspension(suspensionData).subscribe({
  //     next: () => {
  //       this.ref.close(suspensionData);
  //     },
  //     error: (err) => {
  //       console.error("Failed to create suspension", err);
  //     },
  //   });
  // }
}
