<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>
<div class="flex flex-column flex-auto">
  <div class="grid">
    <div class="col-12">
      <div class="surface-card shadow-2 flex p-3 flex-column md:flex-row">
        <div class="flex align-items-center">
          <i class="pi pi-fw pi-info-circle text-900 text-2xl mr-2"></i>
          <span class="text-900 font-medium text-2xl">Incident Reports</span>
          <!-- Info Icon with Tooltip -->
          <span
            pTooltip="This section allows you to manage and create incident reports."
            tooltipPosition="top"
            class="pi pi-info-circle text-xl cursor-pointer"
            style="margin-left: 10px"
          ></span>
        </div>
        <div class="flex flex-1 justify-content-end align-items-center">
          <!-- Global Search Input Field -->
          <span class="p-input-icon-left">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              placeholder="Search Incident..."
              (input)="onGlobalSearch($event)"
              class="p-inputtext-sm"
            />
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 lg:col-4">
      <div class="surface-card shadow-2 flex p-3 flex-column lg:flex-row">
        <button
          pButton
          class="bg-blue-400"
          icon="pi pi-plus"
          label="Add Incident"
          (click)="showAdd()"
        ></button>
      </div>
    </div>
    <div class="col-12">
      <ng-template #loadedContent>
        <div class="surface-card shadow-2 flex p-3 flex-column">
          <p-table
            *ngIf="incidents && incidents.length > 0; else noDataTemplate"
            [value]="incidents"
            [tableStyle]="{ 'min-width': '100%' }"
            [rowHover]="true"
            [responsiveLayout]="'scroll'"
            scrollHeight="75vh"
          >
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of columns">
                  <span class="font-semibold text-md text-color-secondary">{{
                    col.header
                  }}</span>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-incident>
              <!-- (click)="viewIncident(incident)" -->
              <tr style="cursor: pointer" (click)="viewIncident(incident)">
                <td *ngFor="let col of columns">
                  <div class="flex align-items-center gap-3">
                    <span *ngIf="col.field === 'employee_id'">
                      {{ getEmployeeNameById(incident[col.field]) }}
                    </span>
                    <span *ngIf="col.field === 'transgression_id'">
                      {{ getTransgressionOffenceById(incident[col.field]) }}
                    </span>
                    <span *ngIf="col.field === 'incident_status'">
                      <!-- todo: dependent on incident status, assign severity -->
                      <p-tag
                        [severity]="
                          getIncidentStatusSeverity(incident[col.field])
                        "
                        >{{ incident[col.field] | uppercase }}</p-tag
                      >
                    </span>
                    <span
                      *ngIf="
                        col.field !== 'transgression_id' &&
                        col.field !== 'employee_id' &&
                        col.field !== 'files' &&
                        col.field !== 'incident_status'
                      "
                      class="incident-text-wrap"
                    >
                      {{ incident[col.field] }}
                    </span>
                    <!-- Add a separate <div> for files using innerHTML -->
                    <!-- <div *ngIf="col.field === 'files'" [innerHTML]="getFileLinks(incident[col.field])" (click)="$event.stopPropagation()"></div> -->
                    <div *ngIf="col.field === 'files'">
                      <ng-container
                        *ngFor="let file of getFileLinks(incident[col.field])"
                      >
                        <a
                          href="javascript:void(0);"
                          (click)="
                            downloadFile(
                              file.unique_file_reference,
                              file.original_filename
                            );
                            $event.stopPropagation()
                          "
                        >
                          {{ file.original_filename }}
                        </a>
                        <span *ngIf="!isLastElement(file, incident[col.field])"
                          >,
                        </span>
                      </ng-container>
                    </div>
                  </div>
                </td>
                <td>
                  <button
                    type="button"
                    pButton
                    icon="pi pi-pencil"
                    class="p-button-text p-button-success"
                    (click)="editIncident(incident); $event.stopPropagation()"
                  ></button>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </div>
  </div>
  <ng-template #noDataTemplate>
    <div class="flex p-3 flex-column align-items-center justify-content-center">
      <p>No data available at present</p>
    </div>
  </ng-template>
  <div *ngIf="this.Incidents$ | async; else loadedContent">
    <div class="surface-card shadow-2 flex p-3 flex-column">
      <div class="formgrid p-fluid px-4 py-5 md:px-6 lg:px-8">
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="80px" styleClass="py-4"></p-skeleton>
        </div>
        <div class="field col-12">
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
          <p-skeleton width="100%" height="20px" styleClass="my-2"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
</div>
