import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { BehaviorSubject } from "rxjs";
import { Leave } from "src/app/_share/_models/leave";
import { LeaveService } from "src/app/_share/_services/leave.service";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { FileUpload } from "primeng/fileupload";
import { FilesService } from "src/app/_share/_services/files.service";
import { ChangeDetectorRef } from "@angular/core";

@Component({
  selector: "app-create-leave",
  templateUrl: "./create-leave.component.html",
  styleUrls: ["./create-leave.component.scss"],
})
export class CreateLeaveComponent implements OnInit {
  createLeaves$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  @ViewChild("fubadvanced") fubadvanced!: FileUpload;

  leaveForm: FormGroup = this.formBuilder.group({
    leave_status: ["pending"],
    employee_id: ["", Validators.required],
    leave_action_type: ["", Validators.required],
    sick_leave_amount: ["", Validators.required],
    annual_leave_amount: ["", Validators.required],
    maternity_leave_amount: ["", [Validators.required]],
    family_responsiblity_leave_amount: ["", Validators.required],
    unpaid_leave_amount: ["", Validators.required],
    study_leave_amount: ["", Validators.required],
    start_period: ["", Validators.required],
    end_period: ["", Validators.required],
  });
  editFormGroups: any;
  fb: any;

  editFormData: any;
  isInEditMode: boolean = false;
  employees: any[] = [];
  evidencesFiles: any[] = [];
  generatedFiles: any[] = [];
  adminFiles: any[] = [];
  leave: any;
  uploadedFiles: any[] = [];
  loading: boolean = false;

  statusOptions: { label: string; value: string }[] = [
    { label: "Active", value: "active" },
    { label: "Terminated", value: "terminated" },
  ];
  LeaveTypeOptions: { label: string; value: string }[] = [
    { label: "Annual", value: "Annual" },
    { label: "Sick", value: "Sick" },
    { label: "Maternity", value: "Maternity" },
    { label: "Family Responsibility", value: "Family Responsibility" },
    { label: "Unpaid", value: "Unpaid" },
    { label: "Study", value: "Study" },
  ];
  selectedLeaveType: string = "";
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public leaveService: LeaveService,
    public formBuilder: FormBuilder,
    public employeeService: EmployeeService,
    public filesService: FilesService,
    private cdr: ChangeDetectorRef
  ) {}

  onDateChange(event: any) {
    // Get both the start and end date values
    console.log("Date change event:", event);
    const startDate = this.leaveForm.get("start_period")?.value;
    const endDate = this.leaveForm.get("end_period")?.value;

    // Only calculate leave days when both start and end dates are set
    if (startDate && endDate) {
      this.calculateLeaveDays(startDate, endDate);
    }
    this.cdr.detectChanges(); // Force update
  }

  onLeaveTypeChange(event: any) {
    this.selectedLeaveType = event.value;
    console.log("Selected Leave Type:", this.selectedLeaveType);
    console.log("Leave type change event:", event);

    // Recalculate leave days when leave type changes
    const startDate = this.leaveForm.get("start_period")?.value;
    const endDate = this.leaveForm.get("end_period")?.value;

    // Only calculate leave days when both start and end dates are set
    if (startDate && endDate) {
      this.calculateLeaveDays(startDate, endDate);
    }
    this.cdr.detectChanges(); // Force Angular to update the view
  }

  // calculateLeaveDays: Calculates the number of leave days based on start and end period
  calculateLeaveDays(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end < start) {
      console.error("End date must be after the start date");
      return;
    }

    // const diffTime = Math.abs(end.getTime() - start.getTime());
    // const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24)); // Convert to days

    let leaveDays = 0;

    // Loop through each date between the start and end date (inclusive)
    for (
      let currentDate = new Date(start);
      currentDate <= end;
      currentDate.setDate(currentDate.getDate() + 1)
    ) {
      const dayOfWeek = currentDate.getDay();
      console.log("Current date:", currentDate, "Day of week:", dayOfWeek);

      // Check if the current date is not a Saturday (6) or Sunday (0)
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        leaveDays++;
      }
    }

    console.log("Leave Days (excluding weekends):", leaveDays);

    // Reset all leave amounts to zero first
    this.leaveForm.patchValue({
      sick_leave_amount: 0,
      annual_leave_amount: 0,
      maternity_leave_amount: 0,
      family_responsiblity_leave_amount: 0,
      unpaid_leave_amount: 0,
      study_leave_amount: 0,
    });

    // Update the correct field based on the selected leave type
    switch (this.selectedLeaveType) {
      case "Annual":
        this.leaveForm.get("annual_leave_amount")?.setValue(leaveDays);
        break;
      case "Sick":
        this.leaveForm.get("sick_leave_amount")?.setValue(leaveDays);
        break;
      case "Maternity":
        this.leaveForm.get("maternity_leave_amount")?.setValue(leaveDays);
        break;
      case "Family Responsibility":
        this.leaveForm
          .get("family_responsiblity_leave_amount")
          ?.setValue(leaveDays);
        break;
      case "Unpaid":
        this.leaveForm.get("unpaid_leave_amount")?.setValue(leaveDays);
        break;
      case "Study":
        this.leaveForm.get("study_leave_amount")?.setValue(leaveDays);
        break;
      default:
        console.warn("No leave type selected.");
    }
    this.cdr.detectChanges(); // Force update
  }

  ngOnInit(): void {
    this.editFormData = this.config.data?.formData; // Ensure this is set
    if (this.editFormData) {
      this.isInEditMode = true;
    }
    // Simulate loading completion
    setTimeout(() => {
      this.createLeaves$.next(false);
    }, 1000);

    this.loadEmployees();
    this.filterFilesByType();
    this.updateFormData();
  }
  filterFilesByType() {
    if (this.leave && this.leave.files) {
      this.evidencesFiles = this.leave.files.filter(
        (file: any) => file.file_association_type === "evidence"
      );
      this.generatedFiles = this.leave.files.filter(
        (file: any) => file.file_association_type === "generated",
        console.log("generatedFiles", this.generatedFiles)
      );
      this.adminFiles = this.leave.files.filter(
        (file: any) => file.file_association_type === "admin"
      );
    }
  }
  getFileLinks(files: any[]): any[] {
    if (!files || files.length === 0) {
      return [];
    }
    return files.map((file) => ({
      unique_file_reference: file.unique_file_reference,
      original_filename: file.original_filename,
    }));
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.filesService
      .downloadFile(unique_file_reference, original_filename)
      .subscribe(
        () => {
          console.log("File downloaded successfully");
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }

  //bug might be backend related
  onFileUpload(event$: any, uploader: FileUpload) {
    for (let file of event$.files) {
      let formData = new FormData();
      formData.append("file", file);
      if (!this.isInEditMode) {
        this.filesService.uploadHearingFile(formData, 0).subscribe({
          next: (response: any) => {
            console.log("File uploaded successfully", response);
            // Store uploaded file data for later use
            const uploadedFileData = response.body.data; // Capture the response data
            this.uploadedFiles.push(uploadedFileData);
          },
          error: (err: any) => {
            console.error("Error uploading file", err);
          },
        });
      } else {
        this.filesService.uploadHearingFile(formData, this.leave.id).subscribe({
          next: (response: any) => {
            console.log("File uploaded successfully", response);
            // Store uploaded file data for later use
            const uploadedFileData = response.body.data; // Capture the response data
            this.uploadedFiles.push(uploadedFileData);
          },
          error: (err: any) => {
            console.error("Error uploading file", err);
          },
        });
      }
    }
    // Clear the file list in the uploader
    uploader.clear();
  }
  downloadWarningDocument(leave_action_id: number) {
    this.filesService.GenerateLeaveDocument(leave_action_id).subscribe(
      (response: any) => {
        console.log("response received:", response);

        let defaultFileName = `WrittenWarning_${leave_action_id}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  downloadGeneratedFile(file_id: number, leave_id: number) {
    this.filesService.downloadGeneratedFile(file_id, leave_id).subscribe(
      (response: any) => {
        console.log("Response received:", response);

        let defaultFileName = `HearingDocument_${leave_id}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1]
              .replace(/['"]/g, "")
              .replace("utf-8", "")
              .replace(/%20/g, "_")
              .trim(); // Replace %20 with underscores
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }

        // Create a blob and trigger the download
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName; // Use the sanitized filename
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees.map((employee: any) => ({
        label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
        value: employee.id,
      }));
    });
  }

  updateFormData() {
    console.log("editFormData", this.editFormData);
    if (this.editFormData) {
      //settimeout fixes the weird error with 2 different time errors
      // setTimeout(() => {
      // let new_date = new Date(this.editFormData.start_period);
      // let end_date = new Date(this.editFormData.end_period);
      this.leaveForm.patchValue({
        leave_status: this.editFormData.leave_status,
        employee_id: this.editFormData.employee_id,
        leave_action_type: this.editFormData.leave_action_type,
        sick_leave_amount: this.editFormData.sick_leave_amount,
        annual_leave_amount: this.editFormData.annual_leave_amount,
        maternity_leave_amount: this.editFormData.maternity_leave_amount,
        family_responsiblity_leave_amount:
          this.editFormData.family_responsiblity_leave_amount,
        unpaid_leave_amount: this.editFormData.unpaid_leave_amount,
        study_leave_amount: this.editFormData.study_leave_amount,
        start_period: this.editFormData.start_period,
        end_period: this.editFormData.end_period,
      });
      // Manually trigger leave type change
      this.selectedLeaveType = this.editFormData.leave_action_type;
      this.onLeaveTypeChange({ value: this.selectedLeaveType });
      // this.selectedLeaveType = this.editFormData.leave_action_type;

      // // Recalculate leave days
      // if (this.editFormData.start_period && this.editFormData.end_period) {
      //   this.calculateLeaveDays(
      //     this.editFormData.start_period,
      //     this.editFormData.end_period
      //   );
      // }

      this.isInEditMode = true;
      this.leave = this.editFormData; // Store the hearing data
      this.filterFilesByType();
    } else {
      this.isInEditMode = false;
    }
  }

  onAddleaveFormSubmit() {
    this.loading = true;
    if (this.leaveForm.invalid) {
      this.leaveForm.markAllAsTouched();
      return;
    }

    if (this.isInEditMode) {
      this.leaveForm.patchValue({
        // hearing_date: formatDate(
        //   this.leaveForm.value.hearing_date,
        //   "yyyy-MM-dd HH:mm:ss",
        //   "en-US"
        // ),
      });
    }

    const hearingData = {
      ...this.leaveForm.value,
      files: this.uploadedFiles,
    };

    console.log("submitted", hearingData);

    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      this.leaveService
        .updateleave(this.editFormData.id, hearingData)
        .subscribe({
          next: (res: any) => {
            console.log("Hearing updated successfully", res);
            this.ref.close(this.leaveForm.value);
          },
          error: (err: any) => {
            console.error("Error updating hearing", err);
          },
        });
    } else {
      this.leaveService.addLeaveactions(hearingData).subscribe({
        next: (res: any) => {
          console.log("Hearing added successfully", res);
          this.leaveService.refreshleaves();
          this.ref.close(this.leaveForm.value);
        },
        error: (err: any) => {
          console.error("Error adding hearing", err);
        },
      });
    }
  }

  xonAddleaveFormSubmit() {
    this.loading = true;
    if (this.leaveForm.invalid) {
      this.leaveForm.markAllAsTouched();
      return;
    }

    const leaveActionData = {
      ...this.leaveForm.value,
      files: this.uploadedFiles,
    };

    console.log("submitted", leaveActionData);

    if (this.isInEditMode && this.editFormData?.id !== undefined) {
      this.leaveService
        .updateleave(this.editFormData.id, leaveActionData)
        .subscribe({
          next: (res: any) => {
            console.log("Leave action updated successfully", res);
            this.ref.close(this.leaveForm.value);
          },
          error: (err: any) => {
            console.error("Error updating Leave action", err);
          },
        });
    } else {
      this.leaveService.addLeaveactions(leaveActionData).subscribe({
        next: (res: any) => {
          console.log("Leave action added successfully", res);
          this.leaveService.refreshleaves();

          // this.userService.sendSms(this.selectedIncident).subscribe({
          //   next: (res: any) => {
          //     console.log("sms sent successfully", res);
          //   },
          //   error: (err: any) => {
          //     console.error("Error sending sms", err);
          //   },
          // });
          this.ref.close(this.leaveForm.value);
        },
        error: (err: any) => {
          console.error("Error adding leave action ", err);
        },
      });
    }
  }
}
