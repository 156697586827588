import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { log } from "console";
import { MessageService } from "primeng/api";
import { BehaviorSubject, switchMap } from "rxjs";
import { FilesService } from "src/app/_share/_services/files.service";
import { HearingService } from "src/app/_share/_services/hearing.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { TransgressionService } from "src/app/_share/_services/transgression.service";
import { HearingActionService } from "src/app/_share/_services/hearingAction.service";
import { HearingAction } from "src/app/_share/_models/hearingAction";
import { FileUpload } from "primeng/fileupload";

@Component({
  selector: "app-view-hearing",
  templateUrl: "./view-hearing.component.html",
  styleUrls: ["./view-hearing.component.scss"],
})
export class ViewHearingComponent implements OnInit {
  hearings$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  hearing: any; // Adjust to your hearing model
  // hearingForm: FormGroup;
  generatedFiles: any[] = [];
  evidencesFiles: any[] = [];
  adminFiles: any[] = [];
  data: any;
  employees: any[] = [];
  transgressions: any[] = [];
  resolutionOptions: any[];

  // hearingForm: FormGroup: hearingForm = this.formBuilder.group({
  // 	resolution: ["", Validators.required],
  //   });
  hearingForm: FormGroup = this.formBuilder.group({
    resolution: ["", Validators.required],
  });

  constructor(
    public ref: DynamicDialogRef, // Inject DynamicDialogRef
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    public hearingService: HearingService,
    public filesService: FilesService,
    public config: DynamicDialogConfig,
    public employeeService: EmployeeService,
    public transgressionService: TransgressionService,
    public HearingActionService: HearingActionService
  ) {
    // this.hearingForm = this.formBuilder.group({
    // 	verdict: ["", Validators.required],
    // });
    this.resolutionOptions = [
      { label: "Guilty", value: true },
      { label: "Not Guilty", value: false },
    ];
  }

  ngOnInit() {
    this.data = this.config.data.hearing;
    this.hearing = this.data;
    this.loadHearingActions();
    this.loadHearingDetails(); // Load hearing details
    this.filterFilesByType();
  }

  filterFilesByType() {
    if (this.hearing && this.hearing.files) {
      this.evidencesFiles = this.hearing.files.filter(
        (file: any) => file.file_association_type === "evidence"
      );
      // this.generatedFiles = this.hearing.files.filter(
      //   (file: any) => file.file_association_type === "generated"
      // );
      this.adminFiles = this.hearing.files.filter(
        (file: any) => file.file_association_type === "admin"
      );
    }
  }

  loadEmployees() {
    this.employeeService.getEmployees().subscribe((employees) => {
      this.employees = employees.map((employee: any) => ({
        label: `${employee.employee_code} ${employee.first_name} ${employee.last_name}`,
        value: employee.id,
      }));
    });
  }

  loadTransgressions() {
    this.transgressionService
      .getTransgressions()
      .subscribe((transgressions) => {
        this.transgressions = transgressions.map((transgression: any) => ({
          label: `${transgression.category} - ${transgression.offence}`,
          // ${transgression.category}
          value: transgression.id,
          category: transgression.category,
        }));
      });
  }

  uploadedFiles: any[] = [];

  onFileUpload(event$: any, uploader: FileUpload) {
    for (let file of event$.files) {
      let formData = new FormData();
      formData.append("file", file);

      this.filesService.uploadHearingFile(formData, this.hearing.id).subscribe({
        next: (response: any) => {
          console.log("File uploaded successfully", response);
          // Store uploaded file data for later use
          const uploadedFileData = response.body.data; // Capture the response data
          this.uploadedFiles.push(uploadedFileData);
        },
        error: (err: any) => {
          console.error("Error uploading file", err);
        },
      });
    }
    // Clear the file list in the uploader
    uploader.clear();
  }

  downloadFile(unique_file_reference: string, original_filename: string) {
    this.filesService
      .downloadFile(unique_file_reference, original_filename)
      .subscribe(
        () => {
          console.log("File downloaded successfully");
        },
        (error) => {
          console.error("Error downloading file:", error);
        }
      );
  }

  hearingActions: HearingAction[] = [];
  loadHearingActions() {
    this.HearingActionService.get_hearing_actions_by_hearing(
      this.hearing.id
    ).subscribe((hearingActions) => {
      console.log("this is the hearing actions", hearingActions);

      this.hearingActions = hearingActions;
    });
  }

  loadHearingDetails() {
    // Implement your service call to load hearing details
    this.hearings$.next(true); // Set loading to true initially
    this.hearingService
      .getHearings() // Replace with actual service call
      .subscribe(
        (hearing) => {
          this.hearings$.next(false); // Set loading to false after data is loaded
        },
        () => {
          this.hearings$.next(false); // Set loading to false on error
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to load hearing details.",
          });
        }
      );
  }

  //   xconcludeHearing() {
  //     if (this.hearingForm.invalid) {
  //       this.hearingForm.markAllAsTouched();
  //       return;
  //     }

  //     const verdict = this.hearingForm.get("verdict")?.value;

  //     // Logic to update hearing with the verdict
  //     this.hearingService
  //       .updateHearing(this.hearing.id, { ...this.hearing, verdict })
  //       .subscribe(
  //         () => {
  //           this.messageService.add({
  //             severity: "success",
  //             summary: "Success",
  //             detail: "Hearing concluded successfully.",
  //           });
  //         },
  //         (error) => {
  //           this.messageService.add({
  //             severity: "error",
  //             summary: "Error",
  //             detail: "There was an error processing the hearing.",
  //           });
  //         }
  //       );
  //   }
  concludeHearing() {
    if (this.hearingForm.invalid) {
      this.hearingForm.markAllAsTouched();
      return;
    }

    const resolution = this.hearingForm.get("resolution")?.value;
    this.messageService.add({
      severity: "info",
      summary: "Processing",
      detail: "Updating hearing...",
    });

    const updatedHearing = {
      ...this.hearing,
      resolution: resolution, // Include the resolution value
    };

    //Update the hearing with the selected verdict
    this.hearingService
      .updateHearing(this.hearing.id, updatedHearing)
      .pipe(
        switchMap(() => {
          // After updating, determine the action
          return this.hearingService.determineAction(this.hearing.id);
        })
      )
      .subscribe(
        (response) => {
          // Handle success response
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "hearing concluded and action determined successfully.",
          });

          this.ref.close(updatedHearing);
        },
        (error) => {
          // Handle error response
          this.messageService.add({
            severity: "error",
            summary: "Error",
            detail: "There was an error processing the incident.",
          });
        }
      );
  }
}
