import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig } from "primeng/dynamicdialog";
import { BehaviorSubject, Subscription } from "rxjs";
import { Incident } from "src/app/_share/_models/incident";
import { IncidentService } from "src/app/_share/_services/incident.service";

@Component({
  selector: "app-view-employee",
  templateUrl: "./view-employee.component.html",
  styleUrls: ["./view-employee.component.scss"],
})
export class ViewEmployeeComponent implements OnInit {
  employeeIncidentHistoryQuery$: Subscription | undefined;
  employeeIncidentHistory: any[] = [];
  employeeIncidenthistory$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  loadingHistory: boolean = false;

  employee: any;

  constructor(
    private config: DynamicDialogConfig,
    private incidentService: IncidentService
  ) {}

  ngOnInit(): void {
    this.employee = this.config.data;
    this.getEmployeeIncidentHistory();
  }

  getEmployeeIncidentHistory() {
    // Get employee incident history
    this.loadingHistory = true;
    this.employeeIncidentHistoryQuery$ = this.incidentService
      .getIncidentsByEmployeeId(this.employee.id)
      .subscribe((incidents: Incident[]) => {
        this.employeeIncidentHistory = incidents;
        this.employeeIncidenthistory$.next(incidents);
        this.loadingHistory = false;
      });
  }

  ngOnDestroy(): void {
    if (this.employeeIncidentHistoryQuery$) {
      this.employeeIncidentHistoryQuery$.unsubscribe();
    }
  }

  getIncidentStatusSeverity(value: string): string {
    switch (value) {
      case "open":
        return "success";
      case "not guilty":
      case "acknowledged verbal warning":
      case "acknowledged written warning":
      case "acknowledged final written warning":
      case "acknowledge pending hearing":
      case "hearing scheduled":
        return "info";
      case "verbal warning issued":
      case "written warning issued":
      case "final written warning issued":
        return "warning";
      case "pending hearing":
        return "danger";
      default:
        return "info";
    }
  }
}
