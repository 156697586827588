import { Component, OnInit } from "@angular/core";
import { ConfirmationService, MessageService } from "primeng/api";
import { BehaviorSubject, Subscription } from "rxjs";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { SuspensionService } from "src/app/_share/_services/suspensions.service";
import { CreateSuspensionComponent } from "../create-suspension/create-suspension.component";
import { EmployeeService } from "src/app/_share/_services/employee.service";
import { FilesService } from "src/app/_share/_services/files.service";

@Component({
  selector: "app-suspensions",
  templateUrl: "./suspensions.component.html",
  styleUrls: ["./suspensions.component.scss"],
  providers: [ConfirmationService, MessageService],
})
export class SuspensionsComponent implements OnInit {
  Suspensions$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private subscriptions: Subscription = new Subscription();
  ref: DynamicDialogRef | undefined;
  suspensions: any[] = [];
  employees: any[] = [];

  columns = [
    { field: "suspension_status", header: "Status" },
    { field: "employee_id", header: "Employee Name" },
    { field: "start_date", header: "Start Date" },
    { field: "end_date", header: "End Date" },
    { field: "reason", header: "Reason" },
  ];

  constructor(
    public dialogService: DialogService,
    public suspensionService: SuspensionService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public employeeservice: EmployeeService,
    public filesService: FilesService
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.Suspensions$.next(false);
    }, 4000);
    this.loadSuspensions();
    this.loadEmployees();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getEmployeeNameById(id: number): string {
    const employee = this.employees.find((emp: any) => emp.id === id);
    return employee
      ? `${employee.employee_code}  ${employee.first_name} ${employee.last_name}`
      : "loading...";
  }

  loadEmployees() {
    this.employeeservice.getEmployees().subscribe((employees) => {
      this.employees = employees;
    });
  }

  loadSuspensions() {
    this.subscriptions.add(
      this.suspensionService.getSuspensions().subscribe((suspensions) => {
        this.suspensions = suspensions;
      })
    );
  }

  onGlobalSearch(event: any) {
    const searchValue = event.target.value;
    if (searchValue.trim()) {
      this.suspensions = this.filterSuspensions(searchValue);
    } else {
      this.loadSuspensions();
    }
  }

  filterSuspensions(query: string): any[] {
    return this.suspensions.filter((suspension) =>
      Object.values(suspension).some((value) =>
        String(value).toLowerCase().includes(query.toLowerCase())
      )
    );
  }

  getSuspensionStatusSeverity(value: string): string {
    switch (value.toLowerCase()) {
      case "suspended":
      case "unpaid suspension":
        return "warning"; // Set 'danger' for suspended status
      case "suspension period completed":
      case "unpaid suspension period completed":
        return "success"; // Set 'success' for active status
      case "pending":
        return "danger"; // Set 'warning' for pending status
      default:
        return "info"; // Default to 'info' for other cases
    }
  }
  //test
  GeneratedSuspensionNotice(suspensionID: number) {
    this.filesService.GenerateSuspensionNotice(suspensionID).subscribe(
      (response: any) => {
        console.log("response received:", response);

        let defaultFileName = `WrittenWarning_${suspensionID}.docx`;
        let fileName = defaultFileName;

        const contentDispositionHeader = response.headers.get(
          "Content-Disposition"
        );

        if (contentDispositionHeader) {
          console.log("Content-Disposition header:", contentDispositionHeader);

          const matches = contentDispositionHeader.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (matches && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          } else {
            console.warn(
              "Filename not found in Content-Disposition header. Using default."
            );
          }
        } else {
          console.warn("No Content-Disposition header found. Using default.");
        }
        const blob: Blob = response.body as Blob;
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = fileName;
        link.click();

        window.URL.revokeObjectURL(downloadUrl);
      },
      (error) => {
        console.error("Error downloading document:", error);
      }
    );
  }

  // Show Add Suspension Dialog
  showAdd() {
    this.ref = this.dialogService.open(CreateSuspensionComponent, {
      data: null,
      header: "Add Suspension",
      width: "65%",
      height: "55%",
      closeOnEscape: true,
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.loadSuspensions();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Suspension successfully added",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Adding suspension was cancelled",
        });
      }
    });
  }

  editSuspension(suspension: any) {
    this.ref = this.dialogService.open(CreateSuspensionComponent, {
      header: "Edit Suspension",
      width: "65%",
      height: "55%",
      data: {
        formData: suspension, // Pass the selected suspension row data
      },
    });

    this.ref.onClose.subscribe((result) => {
      if (result) {
        this.loadSuspensions();
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Suspension updated successfully",
        });
      } else {
        this.messageService.add({
          severity: "info",
          summary: "Cancelled",
          detail: "Editing suspension was cancelled",
        });
      }
    });
    // Handle dialog close event
    // this.ref.onClose.subscribe((updatedData) => {
    //   if (updatedData) {
    //     this.updateTableRow(suspension.id, updatedData);
    //   }
    // });
  }

  updateTableRow(id: number, updatedData: any) {
    const index = this.suspensions.findIndex((row) => row.id === id);
    if (index !== -1) {
      this.suspensions[index] = updatedData; // Update table with new data
    }
  }
  // View Suspension Details
  showViewSuspension(rowData: any) {}

  deleteSuspension(rowData: any) {
    this.confirmationService.confirm({
      message: "Are you sure you want to delete this Suspension?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.suspensionService.deleteSuspension(rowData.id).subscribe(() => {
          this.loadSuspensions();
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Suspension deleted successfully",
          });
        });
      },
      reject: () => {
        this.messageService.add({
          severity: "info",
          summary: "Rejected",
          detail: "Suspension was not deleted",
        });
      },
    });
  }
}
