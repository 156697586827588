import { Injectable, Input } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { ApiService } from "src/app/_core/_services/api.service";
import { Hearing } from "../_models/hearing";
import { map, switchMap, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class HearingService {
  // private hearings: Hearing[] = [];
  private hearings$ = new BehaviorSubject<Hearing[]>([]);

  constructor(public api: ApiService, private http: HttpClient) {}
  //works
  // determineAction(hearingId: number): Observable<any> {
  //   return this.api.postAPI(`hearings/${hearingId}/determine-action`, {});
  // }

  determineAction(hearingId: number, action_taken: string): Observable<any> {
    return this.api.postAPI(
      `hearings/${hearingId}/determine-action?action_taken=${action_taken}`,
      {}
    );
  }

  getHearings(): Observable<Hearing[]> {
    return this.api.getAPI<Hearing[]>("hearings").pipe(
      map((res) =>
        res.body.sort((a: { id: number }, b: { id: number }) => a.id - b.id)
      ),
      tap((hearings) => this.hearings$.next(hearings))
    );
  }

  addHearings(newhearing: Hearing): Observable<any> {
    return this.api.postAPI<Hearing>("hearings", newhearing).pipe(
      tap((res: any) => {
        console.log("newhearing scheduled", res);
      }),
      switchMap(() => this.refreshHearings())
    );
  }

  updateHearing(hearingId: number, hearingData: Hearing): Observable<any> {
    return this.api.patchAPI<Hearing>("hearings", hearingId, hearingData).pipe(
      tap((res: any) => {
        console.log("hearing updated", res);
      }),
      switchMap(() => this.refreshHearings())
    );
  }

  //test
  refreshHearings(): Observable<any> {
    return this.getHearings().pipe(
      tap((hearings) => {
        this.hearings$.next(hearings);
      })
    );
  }
}
